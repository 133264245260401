import React, { useEffect, useState } from "react";
import { useFirebase } from "../Firebase/FirebaseContext";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../redux/systemSlice";
import "./onboarding.css";
import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CountryCodeSelector from "../New/InputField/CountryCodeSelector";
import Images from "../../assets/images/images";
import { useNavigate } from "react-router-dom";
import InputField from "../New/InputField/InputField";

const Onboarding = ({ refereshUserData }) => {
  const currentUserData = useSelector((state) => state.system.userData);

  const { user } = useFirebase();
  const [showUserForm, setShowUserForm] = useState(false);
  const [showOnboardingSteps, setShowOnboardingSteps] = useState(false);
  const [userData, setUserData] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    console.log("USER DATA :::", user);

    dispatch(showLoader(false));
    // if (user.displayName) {
    //   //   navigate("/new-ui");
    //   setShowOnboardingSteps(true);
    // } else {
    //   console.log("User has no name");
    //   setShowUserForm(true);
    // }
  }, []);

  const getUserData = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": user.accessToken,
      },

      // /7400188864-neelam
    };
    console.log("Getting API User data");
    axios
      .get("/auth/user/", config)
      .then((response) => {
        console.log("API User data:", response.data);
        dispatch(setUserData(response.data));
        // setUserDataSync(true);
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };
  useEffect(() => {
    console.log("User data is updated", currentUserData);
    if (user.displayName) {
      //   navigate("/new-ui");
      setShowOnboardingSteps(true);
    } else {
      console.log("User has no name");
      setShowUserForm(true);
    }
  }, [user, currentUserData]);

  const updateUserData = (userName, userNumber) => {
    console.log("Submitting Details:", userName, userNumber);
    dispatch(showLoader(true));

    const data = {
      userdata: {
        name: userName,
        contact: userNumber,
        userType: "individual",
        businessName: null,
        businessWebsite: null,

        // businessName:
        //   userType == "business" ? businessName.current.value : null,
        // businessWebsite:
        //   userType == "business" ? businessWebsite.current.value : null,
      },
    };
    const headers = {
      "Content-Type": "application/json",
      "X-Auth-Token": user.accessToken,
    };

    axios
      .put("/auth/inituser", data, { headers: headers })
      .then((response) => {
        dispatch(showLoader(false));

        console.log("USER DATA FOR BACKEND:::", response);
        console.log(response.data);
        // refereshUserData();
        setShowUserForm(false);
        setShowOnboardingSteps(true);

        // window.location.reload();
        // getUserData();

        // navigate("/new-ui");
        // props.setNewUser(false);
        // auth().currentUser.reload();
      })
      .catch((error) => {
        dispatch(showLoader(false));
        alert("Server error");
        // alert("Already Registered");
      });
  };

  return (
    <div className="Onboarding">
      <div className="Onboarding__container">
        {showUserForm && <UserDetailsForm onSubmit={updateUserData} />}
        {showOnboardingSteps && (
          <OnboardingSteps
            refereshUserData={refereshUserData}
            user={user}
            currentUserData={currentUserData}
            onSubmit={updateUserData}
          />
        )}
      </div>
    </div>
  );
};

export const UserDetailsForm = ({ onSubmit }) => {
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [enableProceedButton, setEnableProceedButton] = useState(false);
  useEffect(() => {
    if (
      userName.length > 0 &&
      userNumber.length >= 12
      // userNumber.match(/[0-9]{10}/)
    ) {
      setEnableProceedButton(true);
    } else {
      setEnableProceedButton(false);
    }
  }, [userName, userNumber]);

  const onUserDetailsSubmit = () => {
    onSubmit(userName, userNumber);
  };

  return (
    <div className="UserDetailsForm formContainer">
      <h2 className="b800 formHeading">
        Before we proceed, we need some of your personal details
      </h2>
      <InputField
        labelName="Name"
        required={true}
        inputPlaceHolder={"Your Full Name"}
        inputValue={userName}
        inputMaxLength={100}
        onChange={(value) => setUserName(value)}
      />
      <InputField
        labelName="Contact Number"
        inputType={"tel"}
        required={true}
        inputPattern={"[0-9]{10}"}
        inputMaxLength={10}
        inputPlaceHolder={"9999988888"}
        inputValue={userNumber}
        onChange={(value) => setUserNumber(value)}
      />
      {/* <PhoneInput
        placeholder="Enter phone number"
        value={userNumber}
        onChange={setUserNumber}
      /> */}
      <Button
        //   displayText={location === "login" ? "Login" : "Register"}
        displayText={"Proceed"}
        enabled={enableProceedButton}
        onClick={onUserDetailsSubmit}
      />
    </div>
  );
};

// export const InputField = ({
//   labelName,
//   inputType,
//   onChange,
//   inputValue,
//   required,
//   inputPattern,
//   inputMaxLength,
//   inputPlaceHolder,
// }) => {
//   const [countryCode, setCountryCode] = useState("+91");
//   const [inputVal, setInputVal] = useState(inputValue || "");
//   const handleInputChange = (e) => {
//     const numberVal = e.target.value;
//     console.log(e.target.value, numberVal);
//     if (inputType == "tel") {
//       onChange(`${countryCode}${numberVal}`);
//     } else {
//       onChange(numberVal);
//     }
//   };
//   useEffect(() => {
//     if (inputType == "tel") {
//       onChange(`${countryCode}${inputVal}`);
//     }
//   }, [countryCode]);
//   useEffect(() => {
//     if (inputType == "tel") {
//       onChange(`${countryCode}${inputVal}`);
//     } else {
//       onChange(inputVal);
//     }
//   }, [inputVal]);

//   // console.log("IV::", inputValue);
//   return (
//     <div className="InputField">
//       <label className="m300">
//         {labelName}
//         {required && <span>*</span>}
//       </label>
//       <div className="field">
//         {inputType == "tel" && (
//           <CountryCodeSelector onSelect={(val) => setCountryCode(val)} />
//         )}
//         {inputType == "tel" ? (
//           <>
//             <input
//               className="m400"
//               type={inputType || "text"}
//               pattern={inputPattern}
//               maxLength={inputMaxLength}
//               placeholder={inputPlaceHolder}
//               onChange={(e) => setInputVal(e.target.value)}
//             />
//           </>
//         ) : (
//           <>
//             <input
//               className="m400"
//               type={inputType || "text"}
//               pattern={inputPattern}
//               value={inputValue}
//               maxLength={inputMaxLength}
//               placeholder={inputPlaceHolder}
//               onChange={(e) => setInputVal(e.target.value)}
//             />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

const Button = (props) => {
  const buttonType = props.type ? props.type : "primary";
  return (
    <button
      className={`Button--${buttonType}`}
      onClick={props.onClick || null}
      disabled={!props.enabled}
    >
      {props.icon ? props.icon : null}
      {props.displayText || "Click"}
    </button>
  );
};

export const OnboardingSteps = ({
  user,
  currentUserData,
  refereshUserData,
}) => {
  const [WiFiSSID, setWiFiSSID] = useState("");
  const [WiFiPassword, setWiFiPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [enableNext, setEnableNext] = useState(false);
  const [printerName, setPrinterName] = useState("");
  const [printerID, setPrinterID] = useState(null);
  const { setWiFiDetails } = useFirebase();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const headers = {
    "Content-Type": "application/json",
    "X-Auth-Token": user.accessToken,
  };
  useEffect(() => {
    console.log("User updates:::::::::::::::::::::::::::::::", user);
  }, [user]);

  const updateWiFiDetails = () => {
    const data = {
      userdata: {
        wifiSSID: WiFiSSID,
        wifiPassword: WiFiPassword,
      },
    };

    axios
      .put("/auth/updateuserwifi", data, { headers: headers })
      .then((response) => {
        dispatch(showLoader(false));

        console.log("USER DATA FOR BACKEND:::", response);
        console.log(response.data);
        // refereshUserData();
        setCurrentStep((currentStep) => currentStep + 1);
        // navigate("/new-ui");
        // props.setNewUser(false);
        // auth().currentUser.reload();
      })
      .catch((error) => {
        dispatch(showLoader(false));

        // alert("Already Registered");
      });
    // axios
    //   .get("/auth/user/", config)
    //   .then((response) => {
    //     console.log("API User data:", response.data);
    //     dispatch(setUserData(response.data));
    //     // setUserDataSync(true);
    //   })
    //   .catch((error) => {
    //     alert("Already Registered");
    //   });
    // setWiFiDetails(WiFiSSID, WiFiPassword).then((res) => {
    //   console.log("WIFI CONFIGURED:", res);
    // });
  };

  const addNewPrinter = () => {
    // if (newPrinterName.current.value) {
    // setAddingPrinter(false);
    axios
      .post("auth/printers/add", { name: printerName }, { headers: headers })
      .then((response) => {
        dispatch(showLoader(false));

        console.log("Printers Data:", response.data);
        setPrinterID(response.data.id);
        // setUserDataSync(true);
        setCurrentStep((currentStep) => currentStep + 1);
        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.log(error);

        // alert("Already Registered");
      });
  };

  useEffect(() => {
    // console.log(WiFiPassword.length, WiFiSSID.length);
    if (WiFiPassword.length > 0 && WiFiSSID.length > 0) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
  }, [WiFiSSID, WiFiPassword]);

  const handleNavigation = (direction) => {
    if (direction == "next") {
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };
  const handleNextAction = () => {
    switch (currentStep) {
      case 1:
        {
          dispatch(showLoader(true));

          console.log("Completed step:", currentStep);
          console.log("WIFI Details:", WiFiSSID, WiFiPassword);
          updateWiFiDetails();
          // setCurrentStep((currentStep) => currentStep + 1);
        }
        break;
      case 2:
        {
          dispatch(showLoader(true));

          console.log("Adding new printer:", printerName);
          addNewPrinter();
          // setCurrentStep((currentStep) => currentStep + 1);
        }
        break;
    }
  };
  const handleCompleteAction = () => {
    console.log("Onboarding is complete");
    // navigate("/new-ui");
    window.location.href = "/new-ui";
  };

  const handleConfigDownload = () => {
    console.log("Downloading Config file");

    const deviceConfig = {
      id: printerID,
      name: printerName,
      serverURL: "https://asia-south1-cloud-3d-print-359413.cloudfunctions.net",
      apiKey: currentUserData.apiKey,
      wifiSSID: WiFiSSID,
      wifiPassword: WiFiPassword,
    };
    console.log("Printer Data::", deviceConfig);
    // const blob = await deviceConfig.blob(); // blob just as yours
    // const href = await URL.createObjectURL(blob);

    const fileName = "config.json";
    const json = JSON.stringify(deviceConfig, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const downloadConfig = async (printer) => {
  //   const deviceConfig = {
  //     id: printer.id,
  //     name: printer.data.name,
  //     apiKey: userData.apiKey,
  //   };
  //   console.log("Printer Data::", deviceConfig);
  //   // const blob = await deviceConfig.blob(); // blob just as yours
  //   // const href = await URL.createObjectURL(blob);

  //   const fileName = "config.json";
  //   const json = JSON.stringify(deviceConfig, null, 2);
  //   const blob = new Blob([json], { type: "application/json" });
  //   const href = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = href;
  //   link.download = fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <div className="OnboardingSteps formContainer">
      <div>
        <h2 className="b800 formHeading">Let's get started !!</h2>
        <div className="OnboardingSteps__stepDetails">
          <span>{`Step ${currentStep}/3`}</span>
          {currentStep == 1 && (
            <span className="stepDesc m600">
              Add your network (Wi-Fi) details
            </span>
          )}
          {currentStep == 2 && (
            <span className="stepDesc m600">Add your first printer</span>
          )}
          {currentStep == 3 && (
            <span className="stepDesc m600">Download device config file</span>
          )}
        </div>
        {currentStep == 1 && (
          <>
            <InputField
              labelName="SSID"
              required={true}
              inputPlaceHolder={"Your Wi-Fi SSID"}
              inputValue={WiFiSSID}
              inputMaxLength={100}
              onChange={(value) => setWiFiSSID(value)}
            />
            <InputField
              labelName="Password"
              required={true}
              inputMaxLength={100}
              inputPlaceHolder={"Your Wi-Fi Password"}
              inputValue={WiFiPassword}
              onChange={(value) => setWiFiPassword(value)}
            />
          </>
        )}
        {currentStep == 2 && (
          <>
            <InputField
              labelName="Printer Name"
              required={true}
              inputPlaceHolder={"Name your printer"}
              inputValue={printerName}
              inputMaxLength={100}
              onChange={(value) => setPrinterName(value)}
            />
          </>
        )}
        {currentStep == 3 && (
          <>
            <div className="configDownloader">
              <div className="configDownloader__fileImage">
                <img src={Images.fileImage} />
              </div>
              <span className="r400 onboardingDesc">
                <b>Welcome to the Cloud-3D-Print family !!</b>
                <br></br>
                <br></br>You are almost done. Follow the next steps to get
                started:
                <ol>
                  <li>
                    Download the <b>config</b> file for your first printer.
                  </li>
                  <li>
                    Move this file to a clean-formatted SD card & confirm the
                    file name to be: <b>config.json</b>.
                  </li>
                  <li>
                    Connect it to the Cloud-3D-Print device, connected to your
                    printer.
                  </li>
                </ol>
                And now you are all set !!! <br></br>This file will enable your
                printer to get connected to an amazing Cloud-3D-Printing Journey
                ! <br></br>
                <br></br>
                <b>Happy Cloud-3D-Printing !!</b>
              </span>
              <div
                className="fileDownloadButton"
                onClick={handleConfigDownload}
              >
                <div className="fileDetails">
                  <img src={Images.fileImage} />
                  <span className="m400">config.json</span>
                </div>
                <span className="s400">Download</span>
              </div>
            </div>
            <Button
              //   displayText={location === "login" ? "Login" : "Register"}
              displayText={"Done"}
              enabled={true}
              onClick={handleCompleteAction}
            />
          </>
        )}
      </div>
      {currentStep < 3 && (
        <>
          <Button
            //   displayText={location === "login" ? "Login" : "Register"}
            displayText={"Next"}
            enabled={enableNext}
            onClick={handleNextAction}
          />
          {currentStep > 1 && (
            <Button
              type={"secondary"}
              //   displayText={location === "login" ? "Login" : "Register"}
              displayText={"Back"}
              enabled={true}
              onClick={() => handleNavigation("back")}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Onboarding;
