import React from "react";
import "./sideNav.css";
import "../newStyles/color.css";
import Images from "../../../assets/images/images";
import { IoApps, IoFileTray, IoCube, IoSettings } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../redux/systemSlice";
const SideNav = ({ selectedMenuName }) => {
  let dispatch = useDispatch();

  let navigate = useNavigate();
  const menuData = [
    {
      menuName: "Dashboard",
      menuIcon: <IoApps />,
      menuPage: "/new-ui",
    },
    {
      menuName: "Jobs",
      menuPage: "/new-jobs-ui",
      menuIcon: <IoFileTray />,
    },
    {
      menuName: "Printers",
      menuPage: "/printers",
      menuIcon: <IoCube />,
    },
  ];

  const footerMenuData = [
    {
      menuName: "Profile",
      menuPage: "/settings",
      menuIcon: <IoSettings />,
    },
    {
      menuName: "Old Dashboard",
      menuIcon: <IoApps />,
      menuPage: "/dashboard-all",
    },
  ];

  return (
    <div className="SideNav">
      <div className="SideNav__logoContainer">
        <img src={Images.logoWithName} />
      </div>
      <div className="SideNav__menu">
        <div className="SideNav__menuItems">
          {menuData.map((menu, key) => {
            return (
              <div
                key={key}
                className={[
                  "menuItem",
                  selectedMenuName == menu.menuName ? "menuItem--selected" : "",
                ].join(" ")}
                onClick={() => {
                  if (selectedMenuName != menu.menuName) {
                    dispatch(showLoader(true));
                    navigate(menu.menuPage);
                  }
                }}
              >
                {menu.menuIcon}
                <span className="s400">{menu.menuName}</span>
              </div>
            );
          })}
        </div>
        <div className="SideNav__footerMenuItems">
          {footerMenuData.map((menu, key) => {
            return (
              <div
                key={key}
                className={[
                  "menuItem",
                  selectedMenuName == menu.menuName ? "menuItem--selected" : "",
                ].join(" ")}
                onClick={() => {
                  if (selectedMenuName != menu.menuName) {
                    dispatch(showLoader(true));
                    navigate(menu.menuPage);
                  }
                }}
              >
                {menu.menuIcon}
                <span className="s400">{menu.menuName}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
