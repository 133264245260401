import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserPrinters, showLoader } from "../../redux/systemSlice";
import { useFirebase } from "../Firebase/FirebaseContext";
import SideNav from "../New/SideNav/SideNav";
import "./Printers.css";
import {
  IoCloudUploadOutline,
  IoCube,
  IoDownload,
  IoInformation,
  IoInformationCircle,
  IoPencil,
  IoPencilOutline,
  IoPush,
  IoTrash,
  IoTrashBin,
} from "react-icons/io5";
import { GrUpgrade } from "react-icons/gr";
import UserConfirmationBox from "../UserConfirmationBox/UserConfirmationBox";
import Button from "../New/Button/Button";
import axios from "axios";

const Printers = ({ refreshPrinters }) => {
  const {
    user,
    getPrinters,
    listenPrinters,
    printersData,
    getAllStorageFiles,
    uploadStorageFile,
    getFileMetaData,
    getAllJobs,
    deleteFile,
    listenJob,
    liveJobsData,
    getAllPrintersData,
    listenPrintersByID,
    selectedPrintersData,
    firmwares,
    deletePrinter,
  } = useFirebase();
  let dispatch = useDispatch();
  const currentUserData = useSelector((state) => state.system.userData);
  const [currentSelectedPrinter, setCurrentSelectedPrinter] = useState({});
  const [printers, setPrinters] = useState([]);
  const userPrinters = useSelector((state) => state.system.userPrinters);
  const [showUserConfirmationBox, setShowUserConfirmationBox] = useState(false);
  const [printersFetched, setPrintersFetched] = useState(false);

  const [printerSubscriptions, setPrinterSubscriptions] = useState([]);
  const [dummyFlag, setDummyFlag] = useState(false);
  const [firmwaresList, setFirmwaresList] = useState([]);

  // const getAllPrinters = () => {
  //   getAllPrintersData().then((printers) => {
  //     let allPrinters = [];
  //     printers.forEach((printer) => {
  //       allPrinters.push({ id: printer.id, data: printer.data() });
  //     });
  //     console.debug("ALL PRINTERS:", allPrinters);
  //     setPrinters(allPrinters);
  //   });
  // };

  const downloadPrinterConfig = (printerData) => {
    console.log("Downloading Config file", printerData, currentUserData);

    const deviceConfig = {
      id: printerData.id,
      name: printerData.data.name,
      serverURL: "https://asia-south1-cloud-3d-print-359413.cloudfunctions.net",
      apiKey: currentUserData.apiKey,
      wifiSSID: currentUserData.wifiSSID,
      wifiPassword: currentUserData.wifiPassword,
    };
    console.log("Printer Data::", deviceConfig);
    // const blob = await deviceConfig.blob(); // blob just as yours
    // const href = await URL.createObjectURL(blob);

    const fileName = "config.json";
    const json = JSON.stringify(deviceConfig, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log("USSSPPP:", userPrinters);
    if (userPrinters.length > 0 && printerSubscriptions.length == 0) {
      var allPrinterIds = [];
      userPrinters.forEach((printer) => {
        allPrinterIds.push(printer.id);
      });
      const newSub = listenPrinters(allPrinterIds);
      console.log("CREATING SUB", newSub);
      setPrinterSubscriptions((oldSubs) => [...oldSubs, newSub]);
    }
    if (Object.keys(firmwares).length > 0) {
      let allFirmwares = [];
      Object.keys(firmwares).forEach((fw_key) => {
        allFirmwares.push({
          deviceType: fw_key,
          version: firmwares[fw_key],
        });
      });
      setFirmwaresList(allFirmwares);
    }
    // return () => {
    //   console.log("All printer subs::", printerSubscriptions);
    //   printerSubscriptions.forEach((printer) => {
    //     console.log("Killin printer sub::", printer);
    //     printer();
    //   });
    // };
    // getAllPrinters();
  }, [userPrinters, firmwares]);

  useEffect(() => {
    console.log("PRINTER UPDATESSLL:", printersData);
    if (printerSubscriptions.length > 0) {
      if (userPrinters) {
        var allUserPrinters = userPrinters.map((printer) => {
          if (
            printersData.map((newPrinter) => newPrinter.id).includes(printer.id)
          ) {
            printer = printersData.find(
              (newPrinter) => printer.id == newPrinter.id
            );
          }
          return printer;
        });
        dispatch(setUserPrinters(allUserPrinters));
      }
    }
  }, [printersData]);

  useEffect(() => {
    console.log("PRINTER SUBS UPDTAES::::", printerSubscriptions);
    return () => {
      console.log("All printer subs::", printerSubscriptions);
      printerSubscriptions.forEach((printer) => {
        console.log("Killin printer sub::", printer);
        printer();
      });
    };
  }, [printerSubscriptions]);

  // useEffect(() => {
  //   if (printers.length) {
  //     dispatch(showLoader(false));
  //   }
  // }, [printers]);

  const handleDeletePrinter = (printer) => {
    console.log("User wants to delete this printer:", printer);
    setShowUserConfirmationBox(true);
    setCurrentSelectedPrinter(printer);
  };

  const confirmDeleteSelectedPrinter = () => {
    console.log("Deleteing selected printer: ", currentSelectedPrinter);
    deletePrinter(currentSelectedPrinter.id)
      .then((data) => {
        refreshPrinters();
        setTimeout(() => {
          dispatch(showLoader(false));
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        dispatch(showLoader(false));
      });
  };

  const identifyPrinter = (printerID) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": user.accessToken,
      },
    };
    axios
      .get(`/auth/printers/identify?id=${printerID}`, config)
      .then((response) => {
        console.log(response.data);
        //  setUserData(response.data);
        //  setUserDataSync(true);
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const isPrinterOnline = (printer) => {
    if (printer?.data?.updatedOn) {
      const currentTime = new Date(Date.now());
      const lastOnline = printer.data.updatedOn.toDate();
      const isOnline =
        (currentTime.getTime() - lastOnline.getTime()) / 1000 < 90;
      return isOnline;
    }
    return false;
  };

  const isUpdateAvailable = (printer) => {
    console.log(
      "FWLIST:",
      firmwaresList.map((dev) => dev.deviceType),
      printer.data?.deviceType,
      firmwaresList.find((dev) => dev.deviceType === printer.data?.deviceType)
    );
    if (
      firmwaresList.find((dev) => dev.deviceType === printer.data?.deviceType)
        ?.version !== printer.data?.firmwareVersion
    ) {
      const newVersion = firmwaresList.find(
        (dev) => dev.deviceType === printer.data?.deviceType
      )?.version;
      return newVersion;
    }
    return null;
  };

  const updatePrinterFirmware = (printer) => {
    const newVersion = isUpdateAvailable(printer);
    if (newVersion !== null) {
      const newVersion = firmwaresList.find(
        (dev) => dev.deviceType === printer.data?.deviceType
      )?.version;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": user.accessToken,
        },
      };
      axios
        .get(
          `/auth/printer/update?id=${printer.id}&firmwareVersion=${newVersion}`,
          config
        )
        .then((response) => {
          console.log(response.data);
          //  setUserData(response.data);
          //  setUserDataSync(true);
        })
        .catch((error) => {
          // alert("Already Registered");
        });
    }
  };

  useEffect(() => {
    let refreshHandler = setInterval(() => {
      setDummyFlag((prevFlag) => !prevFlag);
      console.log("Refreshing UI");
    }, 10000);
    return () => {
      clearInterval(refreshHandler);
    };
  }, []);

  return (
    <>
      {/* <SideNav selectedMenuName="Printers" /> */}
      <div className="Printers">
        <div className="Printers__mainContainer">
          {userPrinters.length > 0 ? (
            <>
              <div className="Printers__content">
                {/* <h1 className="b900 heading">Printers</h1> */}

                <ul className="Printers__content__list">
                  {userPrinters.map((printer, key) => {
                    return (
                      <li key={key} className="printerItem">
                        <div className="printerDetails">
                          <div className="printerNameStatus">
                            <div className="printerNameVersion">
                              <span>{printer.data.name}</span>
                              <div className="printerUpdateContainer">
                                <div>
                                  {printer.data?.firmwareVersion && (
                                    <span className="printerFirmwareVersion">
                                      FW: {printer.data?.firmwareVersion}
                                    </span>
                                  )}
                                </div>
                                <div className="printerUpdate">
                                  {isPrinterOnline(printer) &&
                                    isUpdateAvailable(printer) && (
                                      <div
                                        onClick={() =>
                                          updatePrinterFirmware(printer)
                                        }
                                      >
                                        {<IoCloudUploadOutline />}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>

                            {isPrinterOnline(printer) && (
                              <div className="printerOnlineStatus">
                                <span>ONLINE</span>
                              </div>
                            )}
                            {!isPrinterOnline(printer) && (
                              <div className="printerOfflineStatus">
                                <span>OFFLINE</span>
                              </div>
                            )}
                          </div>
                          {printer?.data?.macAddress !== "" ? (
                            <>
                              {isPrinterOnline(printer) && (
                                <span>
                                  {printer?.data?.printerState[0]?.toUpperCase() +
                                    printer?.data?.printerState
                                      ?.substring(1)
                                      .toLowerCase() +
                                    " (" +
                                    printer.data?.printProgress +
                                    "%)"}
                                </span>
                              )}
                              {!isPrinterOnline(printer) && <span>- - -</span>}
                            </>
                          ) : (
                            <span>Not yet initialized</span>
                          )}
                        </div>
                        <ul className="subMenu">
                          <li onClick={() => identifyPrinter(printer.id)}>
                            <IoInformationCircle />
                          </li>
                          <li onClick={() => downloadPrinterConfig(printer)}>
                            <IoDownload />
                          </li>
                          <li onClick={() => handleDeletePrinter(printer)}>
                            <IoTrash />
                          </li>
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : (
            <NoPrinterPlaceHolder printers={userPrinters} />
          )}
        </div>
      </div>
      {showUserConfirmationBox && (
        <UserConfirmationBox
          displayText={`Are you sure you want to delete the "${currentSelectedPrinter.data.name}" printer ?`}
          onYes={() => {
            console.log("Userpressed yes");
            dispatch(showLoader(true));
            confirmDeleteSelectedPrinter();
            setShowUserConfirmationBox(false);
          }}
          onNo={() => setShowUserConfirmationBox(false)}
        />
      )}
    </>
  );
};

const NoPrinterPlaceHolder = ({ printers, printersFetched }) => {
  let dispatch = useDispatch();
  const handlePrinterCreation = () => {
    // dispatch(showCreateJobForm(true));
  };
  return (
    <div className="NoPrinterPlaceHolder">
      {/* {jobsFetched == true && jobs.length == 0 && ( */}
      <>
        <div className="NoPrinterPlaceHolder__content">
          <div>
            <IoCube />
          </div>
          <h2 className="b800">Add your first printer !</h2>
          <p className="r400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer
          </p>
          <Button displayText="Add Printer" onClick={handlePrinterCreation} />
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default Printers;
