import React, { useEffect, useState } from "react";
import {
  IoAtCircle,
  IoCheckbox,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkDoneCircle,
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoSquare,
} from "react-icons/io5";

import "./printerPicker.css";

const PrinterPicker = ({ selectedPrinters, onPrinterSelect, userPrinters }) => {
  const [userPrintersList, setUserPrintersList] = useState(userPrinters);

  const [expandSelector, setExpandSelector] = useState(false);
  const [printerListElement, setPrinterListElement] = useState(null);
  const [dropdownDirection, setDropdownDirection] = useState("down");
  const handleDropdownClick = (e) => {
    console.log(
      window.innerHeight,
      e.target.getClientRects(),
      e.target.getClientRects()[0].bottom
    );
    console.log(window.innerHeight - e.target.getClientRects()[0].bottom);
    if (
      parseInt(
        parseInt(window.innerHeight) -
          parseInt(e.target.getClientRects()[0].bottom)
      ) > 200
    ) {
      console.log("Showing down");
      setDropdownDirection("down");
    } else {
      console.log("Showing up");

      setDropdownDirection("up");
    }
    e.stopPropagation();
    // if (!isUploading) {
    setExpandSelector((state) => !state);
    // }
  };

  const handlePrinterSelection = (printer) => {
    let allPrinters = [];
    if (selectedPrinters.includes(printer)) {
      allPrinters = selectedPrinters.filter((pr) => pr.id != printer.id);
    } else {
      allPrinters = [
        ...selectedPrinters,
        userPrinters.filter((pr) => printer.id == pr.id)[0],
      ];
    }

    onPrinterSelect(allPrinters);
  };

  useEffect(() => {
    var concernedElement = document.querySelector(".PrinterPicker");
    setPrinterListElement(concernedElement);
  }, []);

  useEffect(() => {
    const checkOutClick = (e) => {
      if (printerListElement.contains(e.target)) {
      } else {
        setExpandSelector(false);
      }
    };

    if (printerListElement) {
      window.addEventListener("mousedown", checkOutClick);
    }
    return () => {
      window.removeEventListener("mousedown", checkOutClick);
    };
  });
  return (
    <div
      //   ref={componentRef}
      className={["PrinterPicker", "m400"].join(" ")}
      onClick={handleDropdownClick}
    >
      {selectedPrinters.length > 0
        ? `${selectedPrinters.length} printers selected`
        : "Select Printers"}

      {!expandSelector && <IoChevronDown />}
      {expandSelector && <IoChevronUp />}
      {expandSelector && (
        <div
          className={[
            "printerList",
            dropdownDirection == "up" ? "printerList--up" : "",
          ].join(" ")}
        >
          <ul>
            {userPrintersList.map((printer, key) => {
              return (
                <li
                  key={key}
                  onClick={(e) => {
                    handlePrinterSelection(printer);
                    e.stopPropagation();
                  }}
                >
                  {selectedPrinters.includes(printer) && <IoCheckbox />}
                  {!selectedPrinters.includes(printer) && <IoSquare />}
                  <span className="m400">{printer.data.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PrinterPicker;
