import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserFiles,
  setUserPrinters,
  showCreateJobForm,
  showLoader,
} from "../../../redux/systemSlice";
import Printers from "../../Printers/Printers";
import JobsPage from "../JobsPage/JobsPage";
import Overview from "../Overview/Overview";
import SideNav from "../SideNav/SideNav";
import "./dashboard.css";
import Files from "../../Files/Files";
import Modal from "../../hoc/Modal/Modal";
import CreateJobForm from "../../CreateJobForm/CreateJobForm";
import { useFirebase } from "../../Firebase/FirebaseContext";
import axios from "axios";
const Dashboard = ({ refereshUserData }) => {
  const [refreshJobs, setRefreshJobs] = useState(false);
  const {
    user,
    uploadStorageFile,
    getAllStorageFiles,
    getFileMetaData,
    getAllPrintersData,
  } = useFirebase();
  let dispatch = useDispatch();
  const userFiles = useSelector((state) => state.system.userFiles);
  const userPrinters = useSelector((state) => state.system.userPrinters);

  const [currentView, setCurrentView] = useState("jobs");
  const showJobForm = useSelector((state) => state.system.showCreateJobForm);

  useEffect(() => {
    // dispatch(showLoader(false));
  }, []);
  useEffect(() => {
    console.log("UD updates: ", userFiles, userPrinters);
  }, [userFiles, userPrinters]);
  const handleJobFormClose = () => {
    dispatch(showCreateJobForm(false));
  };
  const [fileUploading, setFileUploading] = useState(false);

  const handleFileUpload = (event) => {
    setFileUploading(true);
    processFile(event.target.files[0]).then((metadata) => {
      console.log("Uploading File", metadata);

      uploadStorageFile(event.target.files[0], metadata)
        .then((data) => {
          console.log("Done:", data);

          // setTimeout(() => {
          updateUserFiles();
          // }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const updateUserFiles = () => {
    getAllStorageFiles().then(async (ufiles) => {
      console.log("USER FILEs:", ufiles);
      let allFiles = [];
      // await files.items.forEach(async (item) => {

      for (let i = 0; i < ufiles.items.length; i++) {
        // console.log("GEting file");

        let fileData = await getFileMetaData(ufiles.items[i]);
        await fileData;
        // console.log("FILE INFO::", fileData);
        allFiles.push({
          name: fileData.name,
          size: fileData.size,
          created: fileData.timeCreated,
          metadata: fileData.customMetadata,
        });
      }
      // });

      console.log("FD:", allFiles);
      dispatch(setUserFiles(allFiles));
      // files.items.forEach((element) => {
      //   console.log(element, element.name);
      // });
      // console.log("Updateing", allFiles);
      // setFiles(allFiles);
    });
  };
  const handleNewJobCreation = (userJobData) => {
    // setNewJobCreation(true);
    // setShowCreateJobForm(false);
    dispatch(showCreateJobForm(false));
    dispatch(showLoader(true));
    console.log("Job DATA Sunbmitted:", userJobData);
    //  const availablePrinters = userPrintersData?.filter((item) => {
    //   if (item.online == true && item.data.printerState == "READY") {
    //     return true;
    //   }
    //   return false;
    // });
    // console.log("PRINTERS AVAILABLE::", availablePrinters);

    // if (availablePrinters.length > 0) {
    //   console.log("PRINTERS AVAILABLE::", availablePrinters);
    //   setPrintersToSelect(availablePrinters);
    // } else {
    //   alert("No Printers available");
    // }

    const jobData = {
      jobName: userJobData.jobName,
      jobPrinters: userJobData.selectedPrinters.map((printer) => printer.id),
      jobFile: userJobData.jobFile.name,
      jobFileMetadata: userJobData.jobFile.metadata,
      jopPrintQuantity: userJobData.printQuantity,
    };
    console.log("SUBMITTING JOB::", jobData);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": user.accessToken,
      },
    };
    axios
      .post("auth/jobs/add", jobData, config)
      .then((response) => {
        // setPrintersToSelect([]);
        console.log("Jobs Data:", response.data);
        setRefreshJobs(true);
        // setUserDataSync(true);
        // getAllJobsData();
        dispatch(showLoader(false));

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };
  const processFile = (file) => {
    return new Promise((resolve, reject) => {
      console.log("Reading FIle:", file);
      let reader = new FileReader();
      let buildSummaryObject = {};

      // Closure to capture the file information.
      reader.onload = function (fileData) {
        console.log(fileData);
        const fileBuildSummary =
          fileData.target.result.split("Build Summary")[1];
        if (fileBuildSummary) {
          console.log("File Buld summary is present");
          console.log("Build Summary:", fileBuildSummary);

          let plasticWeight = String(
            fileBuildSummary.split("Plastic weight: ")[1]
          );
          buildSummaryObject["plasticWeightGms"] = plasticWeight.substring(
            0,
            plasticWeight.indexOf(" ")
          );
          buildSummaryObject["plasticWeightLb"] = plasticWeight.substring(
            plasticWeight.indexOf("(") + 1,
            plasticWeight.indexOf(" lb")
          );

          let buildTime = String(fileBuildSummary.split("Build time: ")[1]);
          buildSummaryObject["buildTimeHrs"] = buildTime.substring(
            0,
            buildTime.indexOf(" ")
          );
          buildSummaryObject["buildTimeMins"] = buildTime.substring(
            buildTime.indexOf("hours ") + 6,
            buildTime.indexOf(" minutes")
          );

          let filamentLength = String(
            fileBuildSummary.split("Filament length: ")[1]
          );
          buildSummaryObject["filamentLengthMm"] = filamentLength.substring(
            0,
            filamentLength.indexOf(" ")
          );
          buildSummaryObject["filamentLengthM"] = filamentLength.substring(
            filamentLength.indexOf("(") + 1,
            filamentLength.indexOf(" m)")
          );

          let plasticVolume = String(
            fileBuildSummary.split("Plastic volume: ")[1]
          );
          buildSummaryObject["plasticVolumeMm3"] = plasticVolume.substring(
            0,
            plasticVolume.indexOf(" ")
          );
          buildSummaryObject["plasticVolumeCc"] = plasticVolume.substring(
            plasticVolume.indexOf("(") + 1,
            plasticVolume.indexOf(" cc)")
          );
          let updatedBuildSummaryObject = {};

          if (
            Object.keys(buildSummaryObject).filter(
              (item) =>
                buildSummaryObject[item] == undefined ||
                buildSummaryObject[item] == ""
            ).length
          ) {
            console.log("Unknown Buld summary is present");
            buildSummaryObject = { invalid: true, summary: fileBuildSummary };
            console.log("Data:", buildSummaryObject);
            resolve(buildSummaryObject);
          } else {
            console.log("Build summary extracted successfully");

            let parseError = false;
            Object.keys(buildSummaryObject).map((key) => {
              updatedBuildSummaryObject[key] = parseFloat(
                buildSummaryObject[key]
              );

              if (isNaN(parseFloat(buildSummaryObject[key]))) {
                parseError = true;
              }
            });
            if (parseError) {
              console.log("Error Data:", updatedBuildSummaryObject);
              buildSummaryObject = {
                ...buildSummaryObject,
                parseError: true,
              };
              resolve(buildSummaryObject);
            } else {
              updatedBuildSummaryObject = {
                parseError: false,
                invalid: false,
                ...updatedBuildSummaryObject,
              };
              console.log("Data:", updatedBuildSummaryObject);
              resolve(updatedBuildSummaryObject);
            }
          }
        } else {
          console.log("No Buld summary is present");
          resolve(buildSummaryObject);
        }

        // return function (e) {
        //   // jQuery("#ms_word_filtered_html").val(e.target.result);
        // };
      };

      // Read in the image file as a data URL.
      reader.readAsText(file);
    });
  };
  const getAllPrinters = () => {
    getAllPrintersData().then((printers) => {
      let allPrinters = [];
      printers.forEach((printer) => {
        allPrinters.push({ id: printer.id, data: printer.data() });
      });
      console.debug("ALL PRINTERS:", allPrinters);
      // setPrinters(allPrinters);
      dispatch(setUserPrinters(allPrinters));
    });
  };
  useEffect(() => {
    console.log("Files Updates");
    if (fileUploading) {
      setFileUploading(false);
    }
  }, [userFiles]);
  useEffect(() => {
    updateUserFiles();
    getAllPrinters();
  }, []);

  return (
    <>
      {/* <SideNav selectedMenuName="Dashboard" /> */}
      <div className="Dashboard">
        <Overview refereshUserData={refereshUserData} />
        <TabNav
          activeTab={currentView}
          onTabClick={(tab) => setCurrentView(tab)}
        />
        {/* <JobsPage /> */}
        {currentView == "jobs" && (
          <JobsPage
            refreshJobs={refreshJobs}
            onRefreshJobs={() => setRefreshJobs(false)}
          />
        )}
        {currentView == "printers" && (
          <Printers refreshPrinters={getAllPrinters} />
        )}
        {currentView == "files" && (
          <Files onRefreshFiles={() => updateUserFiles()} />
        )}
      </div>
      {showJobForm && (
        <Modal>
          <CreateJobForm
            userFiles={userFiles}
            onClose={handleJobFormClose}
            onUpload={handleFileUpload}
            isUploading={fileUploading}
            onCreate={handleNewJobCreation}
            userPrinters={userPrinters}
          />
        </Modal>
      )}
    </>
  );
};

const TabNav = ({ activeTab, onTabClick }) => {
  const handleActiveTab = (tabName) => {
    onTabClick(tabName);
  };
  return (
    <div className="TabNav">
      <span
        className={[
          "TabNav__tab",
          activeTab == "jobs" ? "TabNav__active" : "",
        ].join(" ")}
        onClick={() => handleActiveTab("jobs")}
      >
        Jobs
      </span>
      <span
        className={[
          "TabNav__tab",
          activeTab == "printers" ? "TabNav__active" : "",
        ].join(" ")}
        onClick={() => handleActiveTab("printers")}
      >
        Printers
      </span>
      <span
        className={[
          "TabNav__tab",
          activeTab == "files" ? "TabNav__active" : "",
        ].join(" ")}
        onClick={() => handleActiveTab("files")}
      >
        Files
      </span>
    </div>
  );
};

export default Dashboard;
