import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CountryCodeSelector from "./CountryCodeSelector";
import "./inputField.css";

export const InputField = ({
  labelName,
  inputType,
  onChange,
  inputValue,
  required,
  inputPattern,
  inputMaxLength,
  inputPlaceHolder,
}) => {
  const [countryCode, setCountryCode] = useState("+91");
  const [inputVal, setInputVal] = useState(inputValue || "");
  const handleInputChange = (e) => {
    const numberVal = e.target.value;
    console.log(e.target.value, numberVal);
    if (inputType == "tel") {
      onChange(`${countryCode}${numberVal}`);
    } else {
      onChange(numberVal);
    }
  };
  useEffect(() => {
    if (inputType == "tel") {
      onChange(`${countryCode}${inputVal}`);
    }
  }, [countryCode]);
  useEffect(() => {
    if (inputType == "tel") {
      onChange(`${countryCode}${inputVal}`);
    } else {
      onChange(inputVal);
    }
  }, [inputVal]);

  // console.log("IV::", inputValue);
  return (
    <div className="InputField">
      <label className="m300">
        {labelName}
        {required && <span>*</span>}
      </label>
      <div className="field">
        {inputType == "tel" && (
          <CountryCodeSelector onSelect={(val) => setCountryCode(val)} />
        )}
        {inputType == "tel" ? (
          <>
            <input
              className="m400"
              type={inputType || "text"}
              pattern={inputPattern}
              maxLength={inputMaxLength}
              placeholder={inputPlaceHolder}
              onChange={(e) => setInputVal(e.target.value)}
            />
          </>
        ) : (
          <>
            <input
              className="m400"
              type={inputType || "text"}
              pattern={inputPattern}
              value={inputValue}
              maxLength={inputMaxLength}
              placeholder={inputPlaceHolder}
              onChange={(e) => setInputVal(e.target.value)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InputField;
