import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../redux/systemSlice";
import SideNav from "../SideNav/SideNav";
import { useFirebase } from "../../Firebase/FirebaseContext";

const Profile = () => {
  const { logOut } = useFirebase();
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader(false));
  }, []);

  return (
    <>
      <SideNav selectedMenuName="Profile" />
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <button
            className="Button Button--primary"
            style={{
              padding: "1rem 2rem",
              backgroundColor: "var(--purple)",
            }}
            onClick={logOut}
          >
            Signout
          </button>
        </div>
      </div>
      ;
    </>
  );
};

export default Profile;
