import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../Firebase/FirebaseContext";
import "./homePage.css";

import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../redux/systemSlice";

const HomePage = () => {
  const currentUserData = useSelector((state) => state.system.userData);

  let dispatch = useDispatch();
  const { user } = useFirebase();
  let navigate = useNavigate();
  useEffect(() => {
    if (user && Object.keys(currentUserData).length > 0) {
      dispatch(showLoader(false));
    }
  }, []);
  useEffect(() => {
    if (user && Object.keys(currentUserData).length > 0) {
      if (
        user &&
        Object.keys(currentUserData).length > 0 &&
        currentUserData.accountConfigured
      ) {
        console.log("USER DETAILS OBTAINED: ", user, currentUserData);
        navigate("/new-ui");
      } else {
        if (!currentUserData.accountConfigured) {
          navigate("/getting-started");
        }
      }
    }
  }, [user, currentUserData]);
  console.log("on home page", user);
  return (
    <div className="HomePage">
      <div>
        {/* <button onClick={() => navigate("/login")}>Login/Register</button> */}
        <button className="button--primary" onClick={() => navigate("/login")}>
          Login/Register
        </button>
      </div>
    </div>
  );
};

export default HomePage;
