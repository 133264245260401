import React, { useEffect, useState } from "react";
import {
  IoCaretDown,
  IoChevronDown,
  IoCopy,
  IoRefresh,
  IoSettings,
  IoWifi,
} from "react-icons/io5";
import { useDispatch } from "react-redux";
import Images from "../../../assets/images/images";
import {
  showLoader,
  resetState,
  showSettingsDropdown,
} from "../../../redux/systemSlice";
import { useFirebase } from "../../Firebase/FirebaseContext";
import "./topNav.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../../hoc/Modal/Modal";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import axios from "axios";

const TopNav = ({ refereshUserData }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { logOut, user } = useFirebase();
  const [hideActions, setHideActions] = useState(true);
  const currentUserData = useSelector((state) => state.system.userData);

  console.log("UD::", currentUserData, user);
  useEffect(() => {
    if (!user) {
      setHideActions(true);
    } else {
      if (user.emailVerified) {
        setHideActions(false);
      } else {
        logOut();
      }
    }
  }, [user]);

  const handleLogOut = () => {
    // dispatch(showLoader(true));
    // navigate("/");
    // setTimeout(() => {
    dispatch(resetState());
    logOut();
    // }, 2000);
  };
  console.log("ACTIONS:", hideActions);

  return (
    <>
      {!hideActions ? (
        <div className="TopNav">
          <div className="SideNav__logoContainer">
            <img src={Images.logoWithName} />
          </div>
          {!hideActions && (
            <div className="TopNav__actions">
              {user?.displayName && currentUserData?.accountConfigured && (
                <DropdownMenu
                  refereshUserData={refereshUserData}
                  user={user}
                  currentUserData={currentUserData}
                />
              )}
              <button className="button--secondary" onClick={handleLogOut}>
                Logout
              </button>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const DropdownMenu = ({ user, currentUserData, refereshUserData }) => {
  const [expandDropdown, setExpandDropdown] = useState(false);
  const [dropdownElement, setDropdownElement] = useState(null);
  useEffect(() => {
    // setDropdownElement(concernedElement);
  }, []);

  return (
    <div className="DropdownMenu">
      <div
        className="dropdownMenu__button"
        onClick={() => {
          setExpandDropdown((state) => !state);
        }}
      >
        <IoSettings />
        <span className="m500">Settings</span>
        <IoCaretDown />
      </div>
      {
        expandDropdown && (
          <ExpandedMenu
            user={user}
            currentUserData={currentUserData}
            closeMenu={() => setExpandDropdown(false)}
            refereshUserData={refereshUserData}
          />
        )
        // <ul className="expandedMenu">
        //   <li className="expandedMenu__item">
        //     <span className="menuName">API Key</span>
        //     <br></br>
        //     <span className="propertyPreview">
        //       <span>ahdbh-sdwec-wedwewe-cweewcwe</span>
        //       <IoRefresh />
        //     </span>
        //   </li>
        //   <li className="expandedMenu__item">Network Settings</li>
        // </ul>
      }
    </div>
  );
};

const ExpandedMenu = ({
  closeMenu,
  user,
  currentUserData,
  refereshUserData,
}) => {
  const [menuElement, setMenuElement] = useState(null);
  const [showNetworkSettings, setShowNetworkSettings] = useState(false);
  const [WiFiSSID, setWiFiSSID] = useState(currentUserData.wifiSSID);
  const [WiFiPassword, setWiFiPassword] = useState(
    currentUserData.wifiPassword
  );
  let dispatch = useDispatch();

  const updateWiFiDetails = () => {
    const data = {
      userdata: {
        wifiSSID: WiFiSSID,
        wifiPassword: WiFiPassword,
      },
    };
    const headers = {
      "Content-Type": "application/json",
      "X-Auth-Token": user.accessToken,
    };
    dispatch(showLoader(true));

    axios
      .put("/auth/updateuserwifi", data, { headers: headers })
      .then((response) => {
        dispatch(showLoader(false));

        console.log("USER DATA FOR BACKEND:::", response);
        console.log(response.data);
        refereshUserData();
        closeModal();
        // setCurrentStep((currentStep) => currentStep + 1);
        // navigate("/new-ui");
        // props.setNewUser(false);
        // auth().currentUser.reload();
      })
      .catch((error) => {
        dispatch(showLoader(false));
      });
  };
  useEffect(() => {
    const checkOutClick = (e) => {
      if (menuElement.contains(e.target)) {
      } else {
        closeMenu();
      }
    };

    if (menuElement) {
      window.addEventListener("mousedown", checkOutClick);
    }
    return () => {
      window.removeEventListener("mousedown", checkOutClick);
    };
  }, [menuElement]);

  const handleModalClick = (e) => {
    console.log("Modal clicked");
    // setShowNetworkSettings(false);
  };
  const closeModal = () => {
    setShowNetworkSettings(false);
  };
  useEffect(() => {
    var concernedElement = document.querySelector(".expandedMenu");
    setMenuElement(concernedElement);
  }, []);
  return (
    <>
      <ul className="expandedMenu">
        <li className="expandedMenu__item">
          <h4 className="menuName m300">API Key</h4>
          <span className="propertyPreview">
            <span className="propertyValue m400">
              {Object.keys(currentUserData).length > 0 &&
                currentUserData.apiKey}
              <IoCopy />
            </span>
            {/* <span className="refreshIcon"> */}
            <IoRefresh />
            {/* </span> */}
          </span>
        </li>
        <hr className="dropdownHR"></hr>
        <li
          className="expandedMenu__item dropdownButton s300"
          onClick={() => setShowNetworkSettings(true)}
        >
          <IoWifi />
          Network Settings
        </li>
        {showNetworkSettings && (
          <Modal onClose={handleModalClick}>
            <div className="NetworkSettings ">
              <h2 className="header s600">Network Settings</h2>

              <InputField
                labelName="SSID"
                required={true}
                inputPlaceHolder={"Your Wi-Fi SSID"}
                inputValue={WiFiSSID}
                inputMaxLength={100}
                onChange={(value) => setWiFiSSID(value)}
              />
              <InputField
                labelName="Password"
                required={true}
                inputMaxLength={10}
                inputPlaceHolder={"Your Wi-Fi Password"}
                inputValue={WiFiPassword}
                onChange={(value) => setWiFiPassword(value)}
              />
              <div className="actionButtons">
                <Button
                  displayText="Cancel"
                  type="secondary"
                  onClick={closeModal}
                />
                <Button displayText="Done" onClick={updateWiFiDetails} />
              </div>
            </div>
          </Modal>
        )}
      </ul>
    </>
  );
};

export default TopNav;
