import { createSlice } from "@reduxjs/toolkit";

export const systemSlice = createSlice({
  name: "user",
  initialState: {
    showLoader: true,
    userJobs: [],
    userPrinters: [],
    loaderNextAction: "",
    showCreateJobForm: false,
    showSettingsDropdown: false,
    userData: null,
    userFiles: null,
  },
  reducers: {
    resetState: (state) => {
      // state = {
      //   showLoader: true,
      //   userJobs: [],
      //   userPrinters: [],
      //   loaderNextAction: "",
      //   showCreateJobForm: false,
      //   showSettingsDropdown: false,
      //   userData: null,
      // };
      state.showLoader = true;
      state.userJobs = [];
      state.userPrinters = [];
      state.loaderNextAction = "";
      state.showCreateJobForm = false;
      state.showSettingsDropdown = false;
      state.userData = null;
      state.userFiles = null;
    },
    showLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    updateLoaderNextAction: (state, action) => {
      state.loaderNextAction = action.payload;
    },
    setUserJobs: (state, action) => {
      console.log("AJ:", action.payload);
      state.userJobs = action.payload;
    },
    setUserFiles: (state, action) => {
      console.log("AF:", action.payload);
      state.userFiles = action.payload;
    },
    setUserPrinters: (state, action) => {
      console.log("AP:", action.payload);
      state.userPrinters = action.payload;
    },
    showCreateJobForm: (state, action) => {
      state.showCreateJobForm = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    showSettingsDropdown: (state, action) => {
      state.showSettingsDropdown = action.payload;
    },
  },
});

export const {
  showLoader,
  userData,
  updateLoaderNextAction,
  setUserJobs,
  setUserPrinters,
  showCreateJobForm,
  setUserData,
  showSettingsDropdown,
  resetState,
  setUserFiles,
} = systemSlice.actions;

export default systemSlice.reducer;
