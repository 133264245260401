// import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { showLoader } from "../../redux/systemSlice";
import { useFirebase } from "../Firebase/FirebaseContext";

const ProtectedRoute = ({ children }) => {
  const currentUserData = useSelector((state) => state.system.userData);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const { user } = useFirebase();
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(true);
  console.log("CR:", location, currentUserData);
  // console.log(user);
  // console.log("Check user in Private: ", user);

  const getUserData = () => {
    console.log("Wiating for user data");
  };
  useEffect(() => {
    console.log(
      "UUUU:::",
      user,
      currentUserData ? Object.keys(currentUserData).length : ""
    );
    console.log(
      user != null,
      user != undefined,
      currentUserData &&
        Object.keys(currentUserData).length !== 0 &&
        currentUserData?.accountConfigured
    );
    if (
      user != null &&
      user != undefined &&
      currentUserData &&
      Object.keys(currentUserData).length !== 0 &&
      currentUserData?.accountConfigured
    ) {
      console.log("UD statisfied 1");
      setLoaded(true);
    } else {
      if (user === null || user === undefined) {
        if (user === null) {
          console.log("opening login");
          navigate("/login");
        } else {
          if (user === undefined) {
            dispatch(showLoader(true));
          }
        }
      } else {
        if (user) {
          console.log(
            "USSSSERRRRRRR",
            currentUserData &&
              Object.keys(currentUserData).length > 0 &&
              !currentUserData?.accountConfigured
          );
          if (currentUserData && Object.keys(currentUserData).length > 0) {
            console.log("ccccLocc:", location);
            if (!currentUserData?.accountConfigured) {
              if (location.pathname == "/getting-started") {
                setLoaded(true);
              } else {
                console.log("opening getting-started");
                navigate("/getting-started");
              }
            }
          } else {
            if (currentUserData !== null) {
              console.log("redieredugfjv", location.pathname);
              if (location.pathname == "/getting-started") {
                setLoaded(true);
              } else {
                navigate("/getting-started");
              }
            }
          }
        }
      }
      // if (user === null) {
      //   console.log("UD statisfied");
      //   if (currentUserData.wifiPassword === null) {
      //     console.log("opening getting-started");
      //     navigate("/getting-started");
      //   } else {
      //     console.log("opening login");
      //     navigate("/login");
      //   }
      // } else {
      //   if (user === undefined || Object.keys(currentUserData).length == 0) {
      //     dispatch(showLoader(true));
      //     if (Object.keys(currentUserData).length == 0) {
      //       getUserData();
      //     }
      //   }
      // }
    }
  }, [user, currentUserData]);

  useEffect(() => {
    console.log(
      loaded,
      "LOCATION:",
      location,
      currentUserData && !currentUserData?.accountConfigured,
      location.pathname === "/getting-started"
    );
  }, [loaded]);

  if (loaded) {
    if (!user) {
      return <Navigate to={location} />;
    }
    if (
      currentUserData &&
      !currentUserData?.accountConfigured &&
      location.pathname === "/getting-started"
    ) {
      return children;

      // return <Navigate to={"/getting-started"} />;
    }
    return children;
  } else {
    return "NOP";
    // dispatch(showLoader(true));
  }
  return null;
  // <Backdrop
  //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //   open={open}
  // >
  //   <CircularProgress color="inherit" />
  // </Backdrop>
};

export default ProtectedRoute;
