import React from "react";

//https://github.com/gabotechs/react-gcode-viewer
// import { GCodeViewer } from "react-gcode-viewer";

import { GCodeRenderer, Color, SpeedColorizer } from "gcode-viewer";
import gcodeString from "./BASE_BOX.gcode";

const GcodeTest = () => {
  const renderer = new GCodeRenderer(
    gcodeString,
    800,
    600,
    new Color(0x808080)
  );
  renderer.colorizer = new SpeedColorizer(
    this.renderer.getMinMaxValues().minSpeed || 0,
    this.renderer.getMinMaxValues().maxSpeed
  );

  renderer.render().then(() => console.log("rendering finished"));

  return <div>GcodeTest{renderer.element()}</div>;
};

export default GcodeTest;
