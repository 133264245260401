import React from "react";
import "./LoginPage.css";
import { IoLockClosed } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import {
  IoEye,
  IoEyeOff,
  IoLogoGoogle,
  IoMail,
  IoWarning,
} from "react-icons/io5";
import { useState } from "react";
import { initializeApp } from "firebase/app";

import axios from "axios";
import { useEffect } from "react";
import { useFirebase } from "../Firebase/FirebaseContext";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images/images";
import { useDispatch } from "react-redux";
import { showLoader } from "../../redux/systemSlice";

const LoginPage = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { signUp, logIn, user, sendVerificationEmail, getAllPrintersData } =
    useFirebase();

  const [firebaseInit, setFirebaseInit] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [formType, setFormType] = useState("login");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountConfirmation, setAccountConfirmation] = useState(false);

  const clearEmailError = () => {
    if (emailError) setEmailError("");
  };
  const clearPasswordError = () => {
    if (passwordError) setPasswordError("");
  };

  const getUserData = (token) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };
    // console.log("tOKEN:", token);
    axios
      .get("/auth/user/", config)
      .then((response) => {
        console.log("USER DATA FOR BACKEND:::", response);
        console.log(response.data);

        // var userData = response.data.user.data;
        // dispatch(updateProfileIcon(userData.avatar));
        // dispatch(updateName(userData.name));
        // // dispatch(updateGoogleUserId(userData.googleId));
        // dispatch(updateUserId(userData.userId));
        // dispatch(updateEmail(userData.email));
        // setTimeout(() => {
        // //   dispatch(showLoader(false));
        //   navigate("/");
        // }, 1000);
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const registerUser = () => {
    dispatch(showLoader(true));
    signUp(email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("USER:", user);
        sendVerificationEmail().then(() => {
          dispatch(showLoader(false));

          console.log("Verfifcation email sent");
          alert("Please Verify your email before logging in");
        });

        //         {
        //     "uid": "Q94ywYx54rboHEhvxo0g4xQ6IqS2",
        //     "email": "maker.vinodm@gmail.com",
        //     "emailVerified": false,
        //     "isAnonymous": false,
        //     "providerData": [
        //         {
        //             "providerId": "password",
        //             "uid": "maker.vinodm@gmail.com",
        //             "displayName": null,
        //             "email": "maker.vinodm@gmail.com",
        //             "phoneNumber": null,
        //             "photoURL": null
        //         }
        //     ],
        //     "stsTokenManager": {
        //         "refreshToken": "AOEOulbwcw7dnBs4vB3VYf5pCRXrCODFJk1Gfrs43AnJnVpRYPD7x0PNuY12RzHg7qu60_dR2oUVZsB_p802NbvzzlOVnw5xloC774ML_Bh5S_ekT22pe8osxbBwlNTBWMG7yQQtu_rMcP0uVMcskDWAPjs4w7eTX23J7z-b2bdkxbodJub9D92xtCIuGaMdkhZjtEnmZtCA-NYxYlE_zEBJmRP9k0CQEKNkGzXKz3rmv-Q-bK1iNGE",
        //         "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkMjNmMzc0MDI1ZWQzNTNmOTg0YjUxMWE3Y2NlNDlhMzFkMzFiZDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2xvdWQtM2QtcHJpbnQtMzU5NDEzIiwiYXVkIjoiY2xvdWQtM2QtcHJpbnQtMzU5NDEzIiwiYXV0aF90aW1lIjoxNjYzNDc5MjY3LCJ1c2VyX2lkIjoiUTk0eXdZeDU0cmJvSEVodnhvMGc0eFE2SXFTMiIsInN1YiI6IlE5NHl3WXg1NHJib0hFaHZ4bzBnNHhRNklxUzIiLCJpYXQiOjE2NjM0NzkyNjcsImV4cCI6MTY2MzQ4Mjg2NywiZW1haWwiOiJtYWtlci52aW5vZG1AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbIm1ha2VyLnZpbm9kbUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.N1v-c3Z9usByH-_e_pnqXAKozL_8tkgWBRNHKFfkOUmZa9JU7hrofwGlYB6GZFX4Z9rv51jqBWlaiPOOq4Eqq8-Z8Lz8rZCPiPKOO6cEZz5I8_WqOQIhEsI1LqkPkW2hoZvytibQdY2MmuXxMY6vJBC2F6u3yu8EpYk7FrKWDDohhk8nZgcVd6IKxNeLi7_Xg3VUHKkowOBjsohM5zNKHj5n5MWtR4xzInM7Lo6UfQ4FkgqylVy1ggDtuvwK81Lg7v2JHJG9-3pltjyV3eiaDRIx_f3xIjTr5G40fC_7EihwbxmVpIZKah_4fXk7QtcHRigNyCS118CX2CeLQl3Zpw",
        //         "expirationTime": 1663482867174
        //     },
        //     "createdAt": "1663479266547",
        //     "lastLoginAt": "1663479266547",
        //     "apiKey": "AIzaSyBnirdXkvTR4t3BkYU4e22qiJASa_9itoM",
        //     "appName": "[DEFAULT]"
        // }
        // ...
      })
      .catch((error) => {
        dispatch(showLoader(false));

        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.code, error.message);
        switch (errorCode) {
          case "auth/email-already-in-use":
            {
              alert("User account already exists. Please Log in.");
            }
            break;

          default:
            alert("Server Error. Please try after sometime");
        }
        // console.log(error);
        // ..
      });
  };
  const signInUser = () => {
    dispatch(showLoader(true));
    logIn(email, password)
      .then((userCredential) => {
        // Signed in
        console.log(userCredential);
        const user = userCredential.user;
        console.log("USER:", user);

        if (user.emailVerified) {
          //Only verified user can access
          console.log("User is verified");

          user.getIdToken().then((data) => {
            console.log("USER TOKEN is obtained", data);
            // getAllPrintersData().then((printerData) => {
            //   console.log(
            //     "Retrieved printer data:",
            //     printerData,
            //     printerData.data
            //   );
            //   if (!user.displayName) {
            //     // props.setNewUser(true);
            //     navigate("/getting-started");
            //   } else {
            //     navigate("/new-ui");
            //   }
            // });

            getAllPrintersData().then((printers) => {
              let allPrinters = [];
              printers.forEach((printer) => {
                allPrinters.push({ id: printer.id, data: printer.data() });
              });
              console.debug("ALL PRINTERS:", allPrinters);
              if (!user.displayName || allPrinters.length == 0) {
                // props.setNewUser(true);
                navigate("/getting-started");
              } else {
                navigate("/new-ui");
              }
            });
            // localStorage.setItem("authToken", data);
            // getUserData(data);
          });
        } else {
          alert("Please verify your email first !!");
          dispatch(showLoader(false));
        }

        //         {
        //     "uid": "GNHY8mgxlqVqtR3JfzltbKVnbvT2",
        //     "email": "vinteq.solutions@gmail.com",
        //     "emailVerified": false,
        //     "isAnonymous": false,
        //     "providerData": [
        //         {
        //             "providerId": "password",
        //             "uid": "vinteq.solutions@gmail.com",
        //             "displayName": null,
        //             "email": "vinteq.solutions@gmail.com",
        //             "phoneNumber": null,
        //             "photoURL": null
        //         }
        //     ],
        //     "stsTokenManager": {
        //         "refreshToken": "AOEOula2zbH6ISqey_SOZVYrfCjns9flI6s8z1y-QbWQVn7Iabl9MPPggqHcG_6nkPU_Wr5zFktSRQcr5YvBOROCR5mCyvcO2c8v7BpAmdNDKy_JtYgurnkTD0BnxJr08dw5z_SerAIQQHO56KMtHFbPoF_7K-ToKi-Y-Jvp7KXcQszlC_hNqbJc9tBNnQndG03A0FUXqJIZbQycraHNMp0iPo_TWXb0hWvnIrktTa79w6HdQEqBBSw",
        //         "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkMjNmMzc0MDI1ZWQzNTNmOTg0YjUxMWE3Y2NlNDlhMzFkMzFiZDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2xvdWQtM2QtcHJpbnQtMzU5NDEzIiwiYXVkIjoiY2xvdWQtM2QtcHJpbnQtMzU5NDEzIiwiYXV0aF90aW1lIjoxNjYzNDc5Njg5LCJ1c2VyX2lkIjoiR05IWThtZ3hscVZxdFIzSmZ6bHRiS1ZuYnZUMiIsInN1YiI6IkdOSFk4bWd4bHFWcXRSM0pmemx0YktWbmJ2VDIiLCJpYXQiOjE2NjM0Nzk2ODksImV4cCI6MTY2MzQ4MzI4OSwiZW1haWwiOiJ2aW50ZXEuc29sdXRpb25zQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ2aW50ZXEuc29sdXRpb25zQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.JXWuntcy7GKBLASDv5SPggwIoWzIZOMuLD0xNscuSflooCu57pGz-7wMWjjEkkttC8ul9UeIqjDFjhydc6HB97iZSei1pmPm45nljOp4pozpjAzfGUvWR7KP-DLGFfvCeiWvTjNv5jCVaV0YFYUqFzu6x9oER9tKRkJAQnuDod_m2SrmCnJ0k81kboSqiDVM5Wi9g_IujD8sBd9ngKL-wmZt30Wb8Hrh9LoHZlwySYRGhzesh0MEWbU9_sc_vmxS47pf10beu-d7ei8tT6VM26TuDznS39M40fwxOLCu9RsPIl1t6xXHUGeEvasdq4OKntGmYQI8r5WGxqAdv18M6g",
        //         "expirationTime": 1663483289867
        //     },
        //     "createdAt": "1663479156256",
        //     "lastLoginAt": "1663479689745",
        //     "apiKey": "AIzaSyBnirdXkvTR4t3BkYU4e22qiJASa_9itoM",
        //     "appName": "[DEFAULT]"
        // }

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.code, error.message);
        dispatch(showLoader(false));

        switch (error.code) {
          case "auth/wrong-password":
            {
              alert("Incorrect Password. Please try again.");
            }
            break;
          case "auth/user-not-found":
            {
              alert("User not yet registered");
            }
            break;
          default:
            alert("Server Error. Please try after sometime");
        }
      });
  };

  // const initAuthObserver = () => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       if (!user.emailVerified) {
  //         //   user.sendEmailVerification();
  //         //   sendEmailVerification(user).then(() => {
  //         //     // Email verification sent!
  //         //     // ...
  //         //     console.log("Verfifcation eail sent");
  //         //   });
  //       } else {
  //         // console.log("USER IS VERIFIED");
  //         if (!user.displayName) {
  //           props.setNewUser(true);
  //         }
  //         user.getIdToken().then((data) => {
  //           // console.log("NEW TOKEN::", data);
  //           getUserData(data);
  //         });
  //       }
  //       // sendEmailVerification(auth.currentUser).then(() => {
  //       //   // Email verification sent!
  //       //   // ...
  //       //   console.log("Verfifcation eail sent");
  //       // });
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       const uid = user.uid;
  //       console.log("AUTH STATE CHANGE:", uid);

  //       const userData = auth.currentUser;
  //       console.log("CURRENT USRER:", userData);
  //       // ...
  //     } else {
  //       console.log("AUTH STATE CHANGE:USER SIGNED OUT");

  //       // User is signed out
  //       // ...
  //     }
  //   });
  // };

  const onSubmitForm = (event) => {
    event.preventDefault();
    console.log("Form is submitted");
    if (formType == "register") {
      registerUser();
    } else {
      signInUser();
    }
  };

  // console.log("LOADING");
  useEffect(() => {
    // if (!firebaseInit) {
    //   setFirebaseInit(true);
    // initAuthObserver();
    // console.log("Attached observer");
    // }
    if (user) {
      if (user.emailVerified) {
        if (user.displayName) {
          navigate("/new-ui");
        } else {
          navigate("/getting-started");
        }
      }
    }
  }, []);

  useEffect(() => {
    dispatch(showLoader(false));
  }, []);

  return (
    <div className="LoginPage">
      {/* <div className="formsSelector"> */}
      {/* <button onClick={() => setFormType("register")}>Register</button>
        <button onClick={() => setFormType("login")}>Login</button> */}
      {/* </div> */}
      <form
        className="LoginPage__container"
        // onSubmit={location === "login" ? loginUser : registerUser}
        onSubmit={onSubmitForm}
      >
        <div className="LoginPage__logoContainer">
          <img src={Images.logo} />
          <h2 className="s600">Cloud 3D Print</h2>
        </div>
        {/* <div className="LoginPage__headContent">
          <img src={Images.logo} alt="logo"></img>
          <h1 className="LoginPage__headText s700">Fitbrisk</h1>
        </div> */}
        {!accountConfirmation ? (
          <>
            <div className="LoginPage__emailInput">
              <EmailInput
                onChange={setEmail}
                value={email}
                showError={emailError}
                onInputFocus={clearEmailError}
              />
            </div>
            <div className="LoginPage__passwordInput">
              <PasswordInput
                onChange={setPassword}
                value={password}
                showError={passwordError}
                onInputFocus={clearPasswordError}
              />
            </div>
            <Button
              //   displayText={location === "login" ? "Login" : "Register"}
              displayText={formType === "login" ? "Login" : "Register"}
            />
            {/* <button className="button--primary">
              {formType === "login" ? "Login" : "Register"}
            </button> */}
            <span className="LoginPage__signUpHint">
              {formType === "login"
                ? "Not yet registered ? "
                : "Already registered ? "}
              <b
                className="signUpLink"
                onClick={() => {
                  formType === "login"
                    ? setFormType("register")
                    : setFormType("login");
                }}
              >
                {formType === "login" ? "Sign Up" : "Log In"}
              </b>
            </span>
            {/* <Divider /> */}
            {/* <Button
              icon={<IoLogoGoogle />}
              //   displayText={
              //     location === "login"
              //       ? "Sign in with Google"
              //       : "Sign up with Google"
              //   }
              displayText={"Sign in with Google"}
              type="secondary"
              //   onClick={handleGoogleSignIn}
            /> */}
          </>
        ) : (
          <>
            <h2>
              Your account Confirmation is still pending. <br />
              Check for the confirmation email sent on your registered email id
              to complete the registration.
            </h2>
          </>
        )}
      </form>
    </div>
  );
};

export const EmailInput = (props) => {
  return (
    <>
      <UserInput
        label={"Email ID"}
        preLabelIcon={<IoMail />}
        type={"email"}
        placeholder="yournname@business.com"
        required={true}
        value={props.value}
        onChange={props.onChange}
        showError={props.showError}
        errorText={props.showError}
        errorIcon={<IoWarning />}
        onInputFocus={props.onInputFocus}
      />
    </>
  );
};

export const PasswordInput = (props) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  return (
    <>
      <UserInput
        label={"Password"}
        type={passwordVisibility ? "text" : "password"}
        required={true}
        preLabelIcon={<IoLockClosed />}
        postInputIcon={passwordVisibility ? <IoEye /> : <IoEyeOff />}
        postInputIconAction={() => {
          console.log("pressed");
          setPasswordVisibility(!passwordVisibility);
        }}
        placeholder="your password"
        value={props.value}
        onChange={props.onChange}
        strictPassword={true}
        showError={props.showError}
        errorText={props.showError}
        errorIcon={<IoWarning />}
        onInputFocus={props.onInputFocus}
      />
    </>
  );
};

const Button = (props) => {
  const buttonType = props.type ? props.type : "primary";
  return (
    <button className={`Button--${buttonType}`} onClick={props.onClick || null}>
      {props.icon ? props.icon : null}
      {props.displayText || "Click"}
    </button>
  );
};

const Divider = () => {
  return <div className="Divider"></div>;
};

const UserInput = (props) => {
  const [value, setValue] = useState(props.value || "");
  const onValueChangeCallback = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const inputGotFocused = () => {
    if (props.onInputFocus) {
      props.onInputFocus();
    }
  };

  return (
    <div className="UserInput">
      {props.label && (
        <div className="UserInput__labelContent">
          {props.preLabelIcon && (
            <div className="preLabelIcon">{props.preLabelIcon}</div>
          )}

          <label className="m300">
            {props.label}
            <span className="required">{props.required && "*"}</span>
          </label>
          {props.postLabelIcon && (
            <div className="postLabelIcon">{props.postLabelIcon}</div>
          )}
        </div>
      )}
      <div
        className={[
          "UserInput__inputContent",
          props.showError ? "error" : "",
        ].join(" ")}
      >
        {props.preInputIcon && (
          <div className="preInputIcon">{props.preInputIcon}</div>
        )}
        <input
          onFocus={inputGotFocused}
          value={value}
          className="UserInput__inputField m400"
          type={props.type || "text"}
          placeholder={props.placeholder || "Enter Text"}
          required={props.required || false}
          onChange={onValueChangeCallback}
          autoComplete="on"
          // pattern={
          //   props.strictPassword
          //     ? "(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          //     : props.inputPattern
          //     ? ".{8,}"
          //     : null
          // }
          disabled={props.disabled || false}
        />
        {props.postInputIcon && (
          <div
            className="postInputIcon"
            onClick={props.postInputIconAction || null}
          >
            {props.postInputIcon}
          </div>
        )}
      </div>
      {props.showError && (
        <div className="UserInput__inputError">
          <div className="errorIcon">{props.errorIcon}</div>
          <label className="errorText m200">{props.errorText}</label>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
