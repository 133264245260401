import React, { useEffect } from "react";
import { useRef } from "react";
import "./UserDataFormPage.css";
import axios from "axios";
import { useState } from "react";
import { useFirebase } from "../Firebase/FirebaseContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader } from "../../redux/systemSlice";

const UserDataFormPage = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { user } = useFirebase();
  const [userToken, setUserToken] = useState(undefined);
  const [userType, setUserType] = useState("");

  const userName = useRef(undefined);
  const userContact = useRef(undefined);
  const businessName = useRef(undefined);
  const businessWebsite = useRef(undefined);

  const updateUserData = () => {
    const data = {
      userdata: {
        name: userName.current.value,
        contact: userContact.current.value,
        userType: userType,
        businessName:
          userType == "business" ? businessName.current.value : null,
        businessWebsite:
          userType == "business" ? businessWebsite.current.value : null,
      },
    };
    const headers = {
      "Content-Type": "application/json",
      "X-Auth-Token": user.accessToken,
    };

    axios
      .put("/auth/inituser", data, { headers: headers })
      .then((response) => {
        console.log("USER DATA FOR BACKEND:::", response);
        console.log(response.data);
        navigate("/new-ui");
        // props.setNewUser(false);
        // auth().currentUser.reload();
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const sumbitUserData = (event) => {
    event.preventDefault();
    console.log("USERDATA", userName.current.value, userContact.current.value);
    // if (userToken) {
    updateUserData();
    // }
  };

  // const initAuthObserver = () => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       user.getIdToken().then((data) => {
  //         setUserToken(data);
  //         // console.log("NEW TOKEN::", data);
  //       });

  //       const userData = auth.currentUser;
  //       console.log("CURRENT USRER:", userData);
  //       // ...
  //     } else {
  //       console.log("AUTH STATE CHANGE:USER SIGNED OUT");

  //       // User is signed out
  //       // ...
  //     }
  //   });
  // };
  const changeUserType = (event) => {
    setUserType(event.target.value);
  };

  useEffect(() => {
    dispatch(showLoader(false));
    if (user.displayName) {
      navigate("/new-ui");
    }
  }, []);
  return (
    <div className="UserDataFormPage">
      <h1>Account Details</h1>
      <h3>Before we move on, we need some more details for your account</h3>
      <hr />
      <form onSubmit={sumbitUserData}>
        <label htmlFor="name">Full Name: </label>
        <input type="text" name="name" id="name" ref={userName} required />

        <label htmlFor="name">User Type: </label>
        <select
          name="userType"
          id="userType"
          onChange={changeUserType}
          required
          placeholder="Select the type of user"
          value={userType}
        >
          <option disabled value="">
            Select a User Type
          </option>
          <option value="business">Business</option>
          <option value="individual">Individual</option>
        </select>

        {userType == "business" && (
          <>
            <label htmlFor="businessName">Business Name: </label>
            <input
              type="text"
              name="businessName"
              id="businessName"
              ref={businessName}
              required
            />

            <label htmlFor="businessWebsite">Website: </label>
            <input
              type="text"
              name="businessWebsite"
              id="businessWebsite"
              ref={businessWebsite}
              required
            />
          </>
        )}

        <label htmlFor="name">Contact Number: </label>
        <input
          type="number"
          name="name"
          id="name"
          ref={userContact}
          required
          minLength="10"
        />
        <button type="submit">Sumbit Details</button>
      </form>
    </div>
  );
};

export default UserDataFormPage;
