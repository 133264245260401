import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  query,
  getDocs,
  onSnapshot,
  where,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  listAll,
  uploadBytes,
  getMetadata,
  deleteObject,
} from "firebase/storage";
import { auth, db, storage } from "../../firebase";
// import "firebase";

const firebaseContext = createContext();

export function FirebaseContextProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [printersData, setPrintersData] = useState([]);
  const [selectedPrintersData, setSelectedPrintersData] = useState(null);
  const [startListening, setStartListening] = useState(false);
  const [dbData, setDbData] = useState(null);
  const [liveJobsData, setLiveJobsData] = useState([]);
  const [subscribedPrinters, setSubscribedPrinters] = useState([]);
  const [firmwares, setFirmwares] = useState([]);

  function logIn(email, password) {
    // const sersistenceSet = setPersistence(auth, browserLocalPersistence);
    // console.log("Wairt start", sersistenceSet);

    // await sersistenceSet;
    // console.log("Wairt complete", sersistenceSet);
    // return signInWithEmailAndPassword(auth, email, password);
    return new Promise((resolve, reject) => {
      setPersistence(auth, browserLocalPersistence)
        .then((data) => {
          console.log("DATAAAAA:::", data);
          resolve(signInWithEmailAndPassword(auth, email, password));
        })
        .catch((error) => {
          // Handle Errors here.
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          reject(errorMessage);
        });
    });
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function sendVerificationEmail() {
    return sendEmailVerification(auth.currentUser);
  }

  function getDBData() {
    const docRef = collection(db, "Organizations");
    return getDocs(docRef);
  }

  function getAllJobs() {
    var allJobs = collection(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "jobs"
    );
    const docs_query = query(allJobs, where("deleted", "==", false));
    return getDocs(docs_query);
  }

  function getSingleJob(jobId) {
    var job = doc(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "jobs",
      jobId
    );
    return getDoc(job);
  }

  function uploadStorageFile(file, fileMetadata) {
    const metadata = {
      customMetadata: fileMetadata,
    };
    console.log("MD:", file, metadata);
    var targetFileRef = ref(
      storage,
      `uploads/${auth.currentUser.uid}/${file.name}`
    );
    return uploadBytes(targetFileRef, file, metadata);
  }

  function deleteFile(fileName) {
    const fileRef = ref(storage, `uploads/${auth.currentUser.uid}/${fileName}`);

    return deleteObject(fileRef);
  }

  function getAllStorageFiles() {
    // var userFilesRef = ref(storage, `uploads/${auth.currentUser.uid}`);
    var userFilesRef = ref(storage, `uploads/${auth.currentUser.uid}`);
    return listAll(userFilesRef);
  }
  function getAllPrintersData() {
    const printersRef = collection(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "printers"
    );
    // setStartListening(true);
    return getDocs(printersRef);
  }

  function getAllFirmwareVersions() {
    // /DeviceFirmwares/CloudPrinters/CloudPrinter-alpha/0.0.11
    const firmwareVersionsRef = doc(db, "DeviceFirmwares", "CloudPrinters");

    getDoc(firmwareVersionsRef).then((res) => {
      setFirmwares(res.data());
    });
  }

  function deletePrinter(printer) {
    const printerRef = doc(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "printers",
      printer
    );

    return deleteDoc(printerRef);
  }

  function getPrinters() {
    const printersRef = collection(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "printers"
    );
    setStartListening(true);
    // return getDocs(printersRef);
  }
  function listenJob(jobID) {
    console.log("Subscribing to Job:", jobID);
    const jobRef = doc(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "jobs",
      jobID
    );
    const unsubscribe = onSnapshot(
      jobRef,
      (doc) => {
        let jobsData = liveJobsData;
        if (jobsData.filter((job) => job.jobID == doc.data().jobID).length) {
          jobsData = jobsData.map((job) => {
            if (job.jobID == doc.data().jobID) {
              job = { ...doc.data() };
            }
            return job;
          });
        } else {
          jobsData.push(doc.data());
        }
        setLiveJobsData(jobsData);
        // console.log("New Current data: ", doc.data());
      },

      (error) => {
        console.log(jobID, " -Job Listners Ended");
        // ...
      }
    );

    return unsubscribe;
  }

  function listenPrinters() {
    const printersRef = collection(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "printers"
    );
    const unsubscribe = onSnapshot(
      printersRef,
      (querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        // console.log("Current data: ", data);
        setPrintersData(data);
      },
      (error) => {
        console.log("Listneres Ended");
        // ...
      }
    );
    return unsubscribe;
  }

  function listenPrinterByID(printerID) {
    const printerRef = doc(
      db,
      "Cloud_3D_Print_Users",
      auth.currentUser.uid,
      "printers",
      printerID
    );
    const unsubscribe = onSnapshot(
      printerRef,
      (printerData) => {
        let data = [...printersData];
        console.log("Old data: ", [...data]);

        // querySnapshot.forEach((doc) => {
        if (data.length > 0) {
          console.log("Old data exisits");
          data = data.map((printer) => {
            if (printer.id == printerData.id) {
              printer.data = printerData.data();
            }
            return printer;
          });
        } else {
          console.log("Old data doed not exisits");

          data.push({ id: printerData.id, data: printerData.data() });
        }
        // });
        console.log("Current data: ", [...data]);
        setPrintersData(data);
      },
      (error) => {
        console.log("Listneres Ended");
        // ...
      }
    );
    return unsubscribe;
  }
  function listenPrintersByID(printerIDs) {
    console.log("Group listening for", printerIDs);
    const printersQuery = query(
      collection(db, "Cloud_3D_Print_Users", auth.currentUser.uid, "printers"),
      where("printerID", "in", [...printerIDs])
    );
    const unsubscribe = onSnapshot(
      printersQuery,
      (querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        console.log("Current Group data: ", data);
        setSelectedPrintersData(data);
      },
      (error) => {
        console.log("Listneres Ended");
        // ...
      }
    );
    return unsubscribe;
  }

  function getFileMetaData(fileRef) {
    return getMetadata(fileRef);
  }

  function setWiFiDetails(ssid, password) {
    const userRef = doc(db, "Cloud_3D_Print_Users", auth.currentUser.uid);
    return setDoc(userRef, {
      wifiPassword: password,
      wifiSSID: ssid,
      accountConfigured: true,
    });
  }
  function getFileMetaDataByName(fileName) {
    const fileRef = ref(storage, `uploads/${auth.currentUser.uid}/${fileName}`);
    return getMetadata(fileRef);
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Old Updates::::", user);
      console.log("Auth Updates::::", currentuser);

      // currentuser &&
      //   currentuser
      //     .getIdToken(/* forceRefresh */ true)
      //     .then(function (idToken) {
      //       console.log("IDT:", idToken);
      //       // Send token to your backend via HTTPS
      //       // ...
      //     })
      //     .catch(function (error) {
      //       // Handle error
      //     });
      setUser(currentuser);
      getAllFirmwareVersions();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (startListening) {
      const unsubscribe = listenPrinters();
      return () => {
        unsubscribe();
      };
    }
  }, [startListening]);

  return (
    <firebaseContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        sendVerificationEmail,
        getDBData,
        getPrinters,
        listenPrinters,
        getAllStorageFiles,
        uploadStorageFile,
        getFileMetaData,
        getFileMetaDataByName,
        getAllJobs,
        deleteFile,
        listenJob,
        getSingleJob,
        getAllPrintersData,
        listenPrinterByID,
        listenPrintersByID,
        setWiFiDetails,
        liveJobsData,
        printersData,
        selectedPrintersData,
        deletePrinter,
        firmwares,
      }}
    >
      {children}
    </firebaseContext.Provider>
  );
}

export function useFirebase() {
  return useContext(firebaseContext);
}
