import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showLoader,
  setUserJobs,
  setUserPrinters,
  setUserFiles,
} from "../../../redux/systemSlice";
import { showCreateJobForm } from "../../../redux/systemSlice";

import SideNav from "../SideNav/SideNav";
import "../newStyles/color.css";
import "./jobs.css";
import Button from "../Button/Button";
import {
  IoAdd,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkDone,
  IoChevronDown,
  IoChevronUp,
  IoClose,
  IoCodeWorking,
  IoCreate,
  IoCube,
  IoDocument,
  IoFileTray,
  IoFileTrayFull,
  IoFilm,
  IoPlay,
  IoPrint,
  IoRefresh,
  IoReload,
  IoRemove,
  IoRocket,
  IoStop,
  IoStopCircle,
  IoStopwatch,
  IoTime,
  IoTimer,
  IoTrash,
  IoWatch,
} from "react-icons/io5";
import Images from "../../../assets/images/images";
import { useFirebase } from "../../Firebase/FirebaseContext";
import Modal from "../../hoc/Modal/Modal";
import { useRef } from "react";
import FilePicker from "../FilePicker/FilePicker";
import axios from "axios";
import PrinterPicker from "../PrinterPicker/PrinterPicker";
import UserConfirmationBox from "../../UserConfirmationBox/UserConfirmationBox";

const JobsPage = ({ refreshJobs, onRefreshJobs }) => {
  const showJobForm = useSelector((state) => state.system.showCreateJobForm);
  const userFiles = useSelector((state) => state.system.userFiles);
  const userPrinters = useSelector((state) => state.system.userPrinters);

  // const [showCreateJobForm, setShowCreateJobForm] = useState(false);
  const [jobsFetched, setJobsFetched] = useState(false);
  const [newJobCreation, setNewJobCreation] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(undefined);
  const [printers, setPrinters] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [jobSubscriptions, setJobSubscriptions] = useState([]);
  const [printerSubscriptions, setPrinterSubscriptions] = useState([]);
  const [showUserConfirmationBox, setShowUserConfirmationBox] = useState(false);

  let dispatch = useDispatch();
  const {
    user,
    getPrinters,
    listenPrinters,
    printersData,
    getAllStorageFiles,
    uploadStorageFile,
    getFileMetaData,
    getAllJobs,
    deleteFile,
    listenJob,
    liveJobsData,
    getAllPrintersData,
    listenPrintersByID,
    selectedPrintersData,
  } = useFirebase();

  // const updateUserFiles = () => {
  //   getAllStorageFiles().then(async (ufiles) => {
  //     console.log("USER FILEs:", ufiles);
  //     let allFiles = [];
  //     // await files.items.forEach(async (item) => {

  //     for (let i = 0; i < ufiles.items.length; i++) {
  //       // console.log("GEting file");

  //       let fileData = await getFileMetaData(ufiles.items[i]);
  //       await fileData;
  //       // console.log("FILE INFO::", fileData);
  //       allFiles.push({
  //         name: fileData.name,
  //         size: fileData.size,
  //         created: fileData.timeCreated,
  //         metadata: fileData.customMetadata,
  //       });
  //     }
  //     // });

  //     console.log("FD:", allFiles);
  //     dispatch(setUserFiles(allFiles));
  //     // files.items.forEach((element) => {
  //     //   console.log(element, element.name);
  //     // });
  //     // console.log("Updateing", allFiles);
  //     setFiles(allFiles);
  //   });
  // };

  useEffect(() => {
    console.log("JOB Updates:", liveJobsData);
    let jobUpdates = jobs;

    liveJobsData.forEach((job) => {
      jobUpdates = jobUpdates.map((jobData) => {
        if (jobData.jobID == job.jobID) {
          jobData = { ...job };
        }
        return jobData;
      });
    });
    setJobs(jobUpdates);
  }, [liveJobsData]);

  useEffect(() => {
    console.log("Printer Updates:", selectedPrintersData);

    const updatedPrinterdata = userPrinters.map((printer) => {
      if (
        selectedPrintersData
          ?.map((uprinter) => uprinter.id)
          .includes(printer.id)
      ) {
        printer = selectedPrintersData.find(
          (uprinter) => uprinter.id == printer.id
        );
      }
      return printer;
    });
    console.log(updatedPrinterdata);

    // return;
    setPrinters(updatedPrinterdata);
    dispatch(setUserPrinters(updatedPrinterdata));
  }, [selectedPrintersData]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": user.accessToken,
    },
  };
  const startJob = (jobId) => {
    console.log("Starting Job:", jobId);
    axios
      .post("auth/jobs/start", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);

        getAllJobsData();
        const newSub = listenJob(jobId);
        setJobSubscriptions((allSubs) => [...allSubs, newSub]);
        const targetJOb = jobs.find((job) => job.jobID === jobId);
        console.log("TJ:", targetJOb);
        const newPrinterSub = listenPrintersByID(
          Object.keys(targetJOb.jobPrinters).map((printerId) => printerId)
        );
        setPrinterSubscriptions((oldSubs) => [...oldSubs, newPrinterSub]);
        console.log("ALL PRINTER SUBSSSS::", printerSubscriptions);
        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };
  const stopJob = (jobId) => {
    console.log("Stopping Job:", jobId);
    axios
      .post("auth/jobs/stop", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);
        getAllJobsData();

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const handleDeleteJob = () => {
    setShowUserConfirmationBox(true);
  };

  const deleteJob = (jobId) => {
    console.log("Deleting Job:", jobId);
    setShowUserConfirmationBox(false);

    axios
      .post("auth/jobs/delete", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);

        getAllJobsData();
        // showLoader(false)
        // const newSub = listenJob(jobId);
        // setJobSubscriptions((allSubs) => [...allSubs, newSub]);
        // const targetJOb = jobs.find((job) => job.jobID === jobId);
        // console.log("TJ:", targetJOb);
        // const newPrinterSub = listenPrintersByID(
        //   Object.keys(targetJOb.jobPrinters).map((printerId) => printerId)
        // );
        // setPrinterSubscriptions((oldSubs) => [...oldSubs, newPrinterSub]);
        // console.log("ALL PRINTER SUBSSSS::", printerSubscriptions);
        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const pauseJob = (jobId) => {
    console.log("Pausing Job:", jobId);
    axios
      .post("auth/jobs/pause", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);
        getAllJobsData();

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };
  const resumeJob = (jobId) => {
    console.log("Resuming Job:", jobId);
    axios
      .post("auth/jobs/resume", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);
        getAllJobsData();

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const getAllJobsData = () => {
    getAllJobs()
      .then((jobsData) => {
        let userJobs = [];
        jobsData.forEach((job) => {
          userJobs.push(job.data());
        });
        console.log("JOBS::", userJobs);

        userJobs = userJobs.sort((a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        });

        console.log(
          "Runing::",
          userJobs.filter((job) => job.jobState == "running").length
        );
        console.log(
          "Finished::",
          userJobs.filter((job) => job.jobState == "finished").length
        );
        setJobs(userJobs);
        dispatch(setUserJobs(userJobs));
        // if (selectedJob == undefined) {
        setSelectedJob(userJobs[0]);
        // }
        userJobs
          .filter((job) => job.jobState == "running")
          .forEach((job) => {
            const newSub = listenJob(job.jobID);
            console.log("NEW SUB::::", newSub);
            setJobSubscriptions((allSubs) => [...allSubs, newSub]);

            // console.log("All Jobs:", jobs);

            console.log("TJ:", job);
            // listenPrintersByID(
            //   Object.keys(job.jobPrinters).map((printerId) => printerId)
            // );

            const newPrinterSub = listenPrintersByID(
              Object.keys(job.jobPrinters).map((printerId) => printerId)
            );
            setPrinterSubscriptions((oldSubs) => [...oldSubs, newPrinterSub]);
            console.log("ALL PRINTER SUBSSSS::", printerSubscriptions);
          });
        if (newJobCreation) {
          dispatch(showLoader(false));
          setNewJobCreation(false);
        }
        setJobsFetched(true);
        if (refreshJobs) {
          onRefreshJobs();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllPrinters = () => {
  //   getAllPrintersData().then((printers) => {
  //     let allPrinters = [];
  //     printers.forEach((printer) => {
  //       allPrinters.push({ id: printer.id, data: printer.data() });
  //     });
  //     console.debug("ALL PRINTERS:", allPrinters);
  //     setPrinters(allPrinters);
  //     dispatch(setUserPrinters(allPrinters));
  //   });
  // };

  // const processFile = (file) => {
  //   return new Promise((resolve, reject) => {
  //     console.log("Reading FIle:", file);
  //     let reader = new FileReader();
  //     let buildSummaryObject = {};

  //     // Closure to capture the file information.
  //     reader.onload = function (fileData) {
  //       console.log(fileData);
  //       const fileBuildSummary =
  //         fileData.target.result.split("Build Summary")[1];
  //       if (fileBuildSummary) {
  //         console.log("File Buld summary is present");
  //         console.log("Build Summary:", fileBuildSummary);

  //         let plasticWeight = String(
  //           fileBuildSummary.split("Plastic weight: ")[1]
  //         );
  //         buildSummaryObject["plasticWeightGms"] = plasticWeight.substring(
  //           0,
  //           plasticWeight.indexOf(" ")
  //         );
  //         buildSummaryObject["plasticWeightLb"] = plasticWeight.substring(
  //           plasticWeight.indexOf("(") + 1,
  //           plasticWeight.indexOf(" lb")
  //         );

  //         let buildTime = String(fileBuildSummary.split("Build time: ")[1]);
  //         buildSummaryObject["buildTimeHrs"] = buildTime.substring(
  //           0,
  //           buildTime.indexOf(" ")
  //         );
  //         buildSummaryObject["buildTimeMins"] = buildTime.substring(
  //           buildTime.indexOf("hours ") + 6,
  //           buildTime.indexOf(" minutes")
  //         );

  //         let filamentLength = String(
  //           fileBuildSummary.split("Filament length: ")[1]
  //         );
  //         buildSummaryObject["filamentLengthMm"] = filamentLength.substring(
  //           0,
  //           filamentLength.indexOf(" ")
  //         );
  //         buildSummaryObject["filamentLengthM"] = filamentLength.substring(
  //           filamentLength.indexOf("(") + 1,
  //           filamentLength.indexOf(" m)")
  //         );

  //         let plasticVolume = String(
  //           fileBuildSummary.split("Plastic volume: ")[1]
  //         );
  //         buildSummaryObject["plasticVolumeMm3"] = plasticVolume.substring(
  //           0,
  //           plasticVolume.indexOf(" ")
  //         );
  //         buildSummaryObject["plasticVolumeCc"] = plasticVolume.substring(
  //           plasticVolume.indexOf("(") + 1,
  //           plasticVolume.indexOf(" cc)")
  //         );
  //         let updatedBuildSummaryObject = {};

  //         if (
  //           Object.keys(buildSummaryObject).filter(
  //             (item) =>
  //               buildSummaryObject[item] == undefined ||
  //               buildSummaryObject[item] == ""
  //           ).length
  //         ) {
  //           console.log("Unknown Buld summary is present");
  //           buildSummaryObject = { invalid: true, summary: fileBuildSummary };
  //           console.log("Data:", buildSummaryObject);
  //           resolve(buildSummaryObject);
  //         } else {
  //           console.log("Build summary extracted successfully");

  //           let parseError = false;
  //           Object.keys(buildSummaryObject).map((key) => {
  //             updatedBuildSummaryObject[key] = parseFloat(
  //               buildSummaryObject[key]
  //             );

  //             if (isNaN(parseFloat(buildSummaryObject[key]))) {
  //               parseError = true;
  //             }
  //           });
  //           if (parseError) {
  //             console.log("Error Data:", updatedBuildSummaryObject);
  //             buildSummaryObject = {
  //               ...buildSummaryObject,
  //               parseError: true,
  //             };
  //             resolve(buildSummaryObject);
  //           } else {
  //             updatedBuildSummaryObject = {
  //               parseError: false,
  //               invalid: false,
  //               ...updatedBuildSummaryObject,
  //             };
  //             console.log("Data:", updatedBuildSummaryObject);
  //             resolve(updatedBuildSummaryObject);
  //           }
  //         }
  //       } else {
  //         console.log("No Buld summary is present");
  //         resolve(buildSummaryObject);
  //       }

  //       // return function (e) {
  //       //   // jQuery("#ms_word_filtered_html").val(e.target.result);
  //       // };
  //     };

  //     // Read in the image file as a data URL.
  //     reader.readAsText(file);
  //   });
  // };

  // const handleFileUpload = (event) => {
  //   setFileUploading(true);
  //   processFile(event.target.files[0]).then((metadata) => {
  //     console.log("Uploading File", metadata);

  //     uploadStorageFile(event.target.files[0], metadata)
  //       .then((data) => {
  //         console.log("Done:", data);

  //         // setTimeout(() => {
  //         updateUserFiles();
  //         // }, 1000);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   });
  // };

  const onSelectJob = (job) => {
    setSelectedJob(job);
  };

  useEffect(() => {
    getAllJobsData();
    // getAllPrinters();
    // updateUserFiles();
  }, []);

  useEffect(() => {
    console.log("SUBS DATA::", jobSubscriptions, printerSubscriptions);

    return () => {
      console.log(
        "UPDATED SUBS DATA::",
        jobSubscriptions,
        printerSubscriptions
      );
      jobSubscriptions.forEach((job) => {
        console.log("Ending job listner:", job);
        job();
      });
      printerSubscriptions.forEach((printerSub) => {
        console.log("Ending printer listner:", printerSub);
        printerSub();
      });
    };
  }, [jobSubscriptions, printerSubscriptions]);

  useEffect(() => {
    if (refreshJobs) {
      getAllJobsData();
    }
  }, [refreshJobs]);

  useEffect(() => {
    console.log("Files Updates");
    if (fileUploading) {
      setFileUploading(false);
    }
  }, [files]);

  useEffect(() => {
    if (jobs.length && userPrinters.length) {
      dispatch(showLoader(false));
    }
    setTimeout(() => {
      dispatch(showLoader(false));
    }, 5000);
  }, [jobs, userPrinters]);

  const handleNewJobCreation = (userJobData) => {
    setNewJobCreation(true);
    // setShowCreateJobForm(false);
    dispatch(showCreateJobForm(false));
    dispatch(showLoader(true));
    console.log("Job DATA Sunbmitted:", userJobData);
    //  const availablePrinters = userPrintersData?.filter((item) => {
    //   if (item.online == true && item.data.printerState == "READY") {
    //     return true;
    //   }
    //   return false;
    // });
    // console.log("PRINTERS AVAILABLE::", availablePrinters);

    // if (availablePrinters.length > 0) {
    //   console.log("PRINTERS AVAILABLE::", availablePrinters);
    //   setPrintersToSelect(availablePrinters);
    // } else {
    //   alert("No Printers available");
    // }

    const jobData = {
      jobName: userJobData.jobName,
      jobPrinters: userJobData.selectedPrinters.map((printer) => printer.id),
      jobFile: userJobData.jobFile.name,
      jobFileMetadata: userJobData.jobFile.metadata,
      jopPrintQuantity: userJobData.printQuantity,
    };
    console.log("SUBMITTING JOB::", jobData);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": user.accessToken,
      },
    };
    axios
      .post("auth/jobs/add", jobData, config)
      .then((response) => {
        // setPrintersToSelect([]);
        console.log("Printers Data:", response.data);
        // setUserDataSync(true);
        getAllJobsData();

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const stopIndividualJob = (jobId, printerID) => {
    console.log("Stopping Job:", jobId);
    axios
      .post(
        "auth/job/printer/control",
        { job: jobId, printerID: printerID, action: "stop" },
        config
      )
      .then((response) => {
        console.log("Job Data:", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const restartIndividualJob = (jobId, printerID) => {
    console.log("Restarting Job:", jobId);
    axios
      .post(
        "auth/job/printer/control",
        { job: jobId, printerID: printerID, action: "start" },
        config
      )
      .then((response) => {
        console.log("Job Data:", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("SUBSS::::", jobSubscriptions);
  }, [jobSubscriptions]);

  return (
    <>
      {/* {showJobForm && (
        <Modal>
          <CreateJobForm
            userFiles={files}
            onClose={handleJobFormClose}
            onUpload={handleFileUpload}
            isUploading={fileUploading}
            onCreate={handleNewJobCreation}
            userPrinters={printers}
          />
        </Modal>
      )} */}
      {/* <SideNav selectedMenuName="Jobs" /> */}
      <div className="Jobs">
        {jobs.length > 0 ? (
          <div className="Jobs__mainContainer">
            {/* <div className="Jobs__headingContainer">
              <h1 className="b900">Jobs</h1>
              <button
                className="button--primarywithIcon"
                onClick={() => setShowCreateJobForm(true)}
              >
                <IoAdd />
                Create Job
              </button>
            </div> */}
            {jobs.length > 0 && (
              <div className="Jobs__content">
                <JobsList
                  jobs={jobs}
                  selectedJob={selectedJob}
                  selectJob={onSelectJob}
                />
                {selectedJob && (
                  <JobPreview
                    startJob={startJob}
                    stopJob={stopJob}
                    resumeJob={resumeJob}
                    deleteJob={handleDeleteJob}
                    job={selectedJob}
                    printers={userPrinters}
                    stopIndividualJob={stopIndividualJob}
                    restartIndividualJob={restartIndividualJob}
                  />
                )}
                {showUserConfirmationBox && (
                  <UserConfirmationBox
                    displayText={`Confirm delete job: ${selectedJob.jobName} ?`}
                    onYes={() => {
                      dispatch(showLoader(true));
                      deleteJob(selectedJob.jobID);
                    }}
                    onNo={() => setShowUserConfirmationBox(false)}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <NoJobPlaceHolder
            handleJobCreation={handleNewJobCreation}
            jobs={jobs}
            jobsFetched={jobsFetched}
          />
        )}
      </div>
    </>
  );
};

const NoJobPlaceHolder = ({ jobs, jobsFetched }) => {
  let dispatch = useDispatch();
  const handleJobCreation = () => {
    dispatch(showCreateJobForm(true));
  };
  return (
    <div className="NoJobPlaceHolder">
      {jobsFetched == true && jobs.length == 0 && (
        <>
          <div className="NoJobPlaceHolder__content">
            <div>
              <IoFileTrayFull />
            </div>
            <h2 className="b800">Create your first job !</h2>
            <p className="r400">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer
            </p>
            <Button displayText="Create Job" onClick={handleJobCreation} />
          </div>
        </>
      )}
    </div>
  );
};

const JobsList = ({ jobs, selectedJob, selectJob }) => {
  const [jobsData, setJobsData] = useState(jobs || []);
  const [jobsFilter, setJobsFilter] = useState("all");

  const handleJobsFilter = (filterType) => {
    var filteredJobs = jobs;
    if (filterType == "running") {
      filteredJobs = jobs.filter((job) => job.jobState == "running");
    }
    if (filterType == "completed") {
      filteredJobs = jobs.filter((job) => job.jobState == "finished");
    }
    setJobsData(filteredJobs);
  };
  // console.log("FJOBS:", jobsData);

  useEffect(() => {
    handleJobsFilter(jobsFilter);
  }, [jobsFilter]);

  useEffect(() => {
    selectJob(jobsData[0]);
  }, [jobsData]);

  useEffect(() => {
    setJobsData(jobs);
    // setJobsFilter("all");
    handleJobsFilter(jobsFilter);
  }, [jobs]);

  return (
    <div className="JobsList">
      <div className="JobsList__types">
        <div
          className={[
            "jobTypeTab",
            jobsFilter == "all" ? "jobTypeTab--selected" : "",
            "s300",
          ].join(" ")}
          onClick={() => setJobsFilter("all")}
        >
          All ({jobs.length})
        </div>
        <div
          className={[
            "jobTypeTab",
            jobsFilter == "running" ? "jobTypeTab--selected" : "",
            "s300",
          ].join(" ")}
          onClick={() => setJobsFilter("running")}
        >
          Ongoing ({jobs.filter((job) => job.jobState == "running").length})
        </div>
        <div
          className={[
            "jobTypeTab",
            jobsFilter == "completed" ? "jobTypeTab--selected" : "",
            "s300",
          ].join(" ")}
          onClick={() => setJobsFilter("completed")}
        >
          Completed ({jobs.filter((job) => job.jobState == "finished").length})
        </div>
      </div>
      <div className="JobsList__list">
        {jobsData.map((job, key) => {
          return (
            <JobCard
              key={key}
              job={job}
              selected={selectedJob?.jobID == job?.jobID ? true : false}
              selectJob={selectJob}
            />
          );
        })}
      </div>
    </div>
  );
};

const JobCard = ({ job, selected, selectJob }) => {
  const jobCompletionPercentage = (job) => {
    return parseInt((job.printedQuantity / job.totalQuantity) * 100);
  };
  const jobRunningPercentage = (job) => {
    return parseInt((job.inPrintQuantity / job.totalQuantity) * 100);
  };
  const jobPendingPercentage = (job) => {
    return parseInt(
      ((job.totalQuantity - (job.inPrintQuantity + job.printedQuantity)) /
        job.totalQuantity) *
        100
    );
  };
  // if (job.jobState === "running") jobCompletionPercentage(job);
  return (
    <div
      className={[
        "JobCard",
        job.jobState == "finished"
          ? "JobCard--completedJob"
          : job.jobState == "stopped"
          ? "JobCard--stoppedJob"
          : job.jobState == "created"
          ? "JobCard--createdJob"
          : "JobCard--runningJob",
        selected ? "JobCard--selected" : "",
      ].join(" ")}
      onClick={() => selectJob(job)}
    >
      <div className="ProgressIndicator">
        {job.jobState === "running" && (
          <>
            <>
              <div
                className="solidLine"
                style={{ width: `${jobCompletionPercentage(job)}%` }}
              ></div>
              <div
                className="runningLine"
                style={{ width: `${jobRunningPercentage(job)}%` }}
              >
                <div className="vertical-stripes"></div>
              </div>
              <div
                className="pendingLine"
                style={{ width: `${jobPendingPercentage(job)}%` }}
              >
                <div className="vertical-stripes"></div>
              </div>
            </>
          </>
        )}
      </div>
      <div className="JobCard__jobDetails">
        <div className="jobThumbnail">
          <img src={Images.logo} />
        </div>
        <div className="jobInfo">
          <h3 className="jobInfo__jobName m400">{job.jobName}</h3>
          <div className="jobInfo__jobFile m300">
            <IoDocument />
            <span>{job.jobFile}</span>
          </div>
          <div className="jobInfo__jobTime m300">
            <IoTime />
            <span>{new Date(job.createdOn.toDate()).toLocaleString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const JobPreview = ({
  job,
  printers,
  startJob,
  stopJob,
  deleteJob,
  resumeJob,
  stopIndividualJob,
  restartIndividualJob,
}) => {
  const jobDuration =
    (job.jobFileMetadata.buildTimeHrs > 0
      ? job.jobFileMetadata.buildTimeHrs + "h "
      : "") +
    job.jobFileMetadata.buildTimeMins +
    "m";

  const materialWeight = Math.round(
    job.jobFileMetadata.plasticWeightGms * job.totalQuantity
  );
  const materialGms =
    materialWeight > 1000
      ? Math.round((materialWeight / 1000) * 100) / 100 + " kg"
      : materialWeight + " gms";

  const timePerPrint =
    parseInt(job.jobFileMetadata.buildTimeMins) +
    parseInt(job.jobFileMetadata.buildTimeHrs) * 60;

  const timeRemain = (job.totalQuantity - job.printedQuantity) * timePerPrint;
  const timeRemaining =
    parseInt(timeRemain / 60) + "h " + (timeRemain % 60) + "m";
  // console.log(timePerPrint, timeRemain, timeRemaining);
  const completedPrints = job.printedQuantity + "/" + job.totalQuantity;

  return (
    <div className="JobPreview">
      <div className="JobPreview__jobDetails">
        <div className="jobThumbnail jobThumbnail--small">
          <img src={Images.logo} />
        </div>
        <div className="jobInfo">
          <h3 className="jobInfo__jobName m400">{job.jobName}</h3>
          <div className="details">
            <div
              className={[
                "details__jobStatus",
                `details__jobStatus--${
                  job.jobState == "finished"
                    ? "completed"
                    : job.jobState == "running"
                    ? "running"
                    : job.jobState == "stopped"
                    ? "stopped"
                    : "created"
                }`,
                "m300",
              ].join(" ")}
            >
              {job.jobState == "finished" ? (
                <IoCheckmarkDone />
              ) : job.jobState == "running" ? (
                <IoRocket />
              ) : job.jobState == "stopped" ? (
                <IoStopCircle />
              ) : (
                <IoCreate />
              )}
              <span>
                {job.jobState == "finished"
                  ? "Completed"
                  : job.jobState == "running"
                  ? "Running"
                  : job.jobState == "stopped"
                  ? "Stopped"
                  : "Created"}
              </span>
            </div>
            <div className="details__jobFile m300">
              <IoDocument />
              <span>{job.jobFile}</span>
            </div>
          </div>
          <div>
            <div className="jobInfo__jobTime m300">
              <IoTime />
              <span>
                on {new Date(job.createdOn.toDate()).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <div className="jobActions">
          {job.jobState != "running" ? (
            <div
              className="jobActions__imageButton"
              onClick={() => deleteJob(job.jobID)}
            >
              <IoTrash />
            </div>
          ) : (
            <div className="jobActions__imageButtonPlaceholder"></div>
          )}

          {job.jobState == "finished" ? (
            <div
              className="jobActions__imageButton"
              onClick={() => startJob(job.jobID)}
            >
              <IoRefresh />
            </div>
          ) : job.jobState == "running" ? (
            <div
              className="jobActions__imageButton"
              onClick={() => stopJob(job.jobID)}
            >
              <IoStop />
            </div>
          ) : job.jobState == "stopped" ? (
            <div
              className="jobActions__imageButton"
              onClick={() => startJob(job.jobID)}
            >
              <IoPlay />
            </div>
          ) : (
            <div
              className="jobActions__imageButton"
              onClick={() => startJob(job.jobID)}
            >
              <IoPlay />
            </div>
          )}
        </div>
      </div>
      <div className="jobDataContainer">
        <div className="JobPreview__printDetails">
          {job.jobState == "running" && (
            <DetailsCard
              icon={<IoCheckmarkDone />}
              text="Completed prints"
              value={job.printedQuantity + "/" + job.totalQuantity}
            />
          )}
          {(job.jobState == "finished" ||
            job.jobState == "stopped" ||
            job.jobState == "created") && (
            <DetailsCard
              icon={<IoCube />}
              text="Total prints"
              value={job.totalQuantity}
            />
          )}
          {job.jobState == "running" && (
            <DetailsCard
              icon={<IoPrint />}
              text="Prints in progress"
              value={job.inPrintQuantity}
            />
          )}

          {job.jobState == "running" && (
            <DetailsCard
              icon={<IoStopwatch />}
              text="Time left (total)"
              value={timeRemaining}
            />
          )}
          {(job.jobState == "finished" || job.jobState == "running") && (
            <DetailsCard
              icon={<IoPrint />}
              text="Printers used"
              value={Object.keys(job.jobPrinters).length}
            />
          )}
          {(job.jobState == "finished" ||
            job.jobState == "stopped" ||
            job.jobState == "created" ||
            job.jobState == "running") && (
            <DetailsCard
              icon={<IoStopwatch />}
              text="Job duration / print"
              value={jobDuration}
            />
          )}
          {(job.jobState == "finished" ||
            job.jobState == "stopped" ||
            job.jobState == "created" ||
            job.jobState == "running") && (
            <DetailsCard
              icon={<IoFilm />}
              text="Material (total)"
              value={materialGms}
            />
          )}
        </div>
        <div className="JobPreview__printerDetails">
          <PrintersList
            jobState={job.jobState}
            printers={printers}
            jobPrinters={job.jobPrinters}
            stopJobForPrinter={(printer) =>
              stopIndividualJob(job.jobID, printer)
            }
            restartJobForPrinter={(printer) =>
              restartIndividualJob(job.jobID, printer)
            }
          />
        </div>
      </div>
    </div>
  );
};

const DetailsCard = ({ text, value, icon }) => {
  return (
    <div className="detailsCard">
      <div className="detailsCard__heading">
        {icon}
        <span className="r300">{text}</span>
      </div>
      <span className="s800">{value}</span>
    </div>
  );
};

const PrintersList = ({
  jobState,
  printers,
  jobPrinters,
  stopJobForPrinter,
  restartJobForPrinter,
}) => {
  const [allJobPrinters, setAllJobPrinters] = useState([]);
  const [dummyFlag, setDummyFlag] = useState(false);
  const jobPrinterKeys = Object.keys(jobPrinters);

  // console.log("JOB STAET PL::", jobState, jobPrinters);
  useEffect(() => {
    // const Jprinters = printers.filter((printer) => {
    //   if (!!jobPrinterKeys.includes(printer.id)) return true;
    //   return false;
    // });

    //  const Jprinters = printers.filter((printer) => {
    //    if (!!jobPrinterKeys.includes(printer.id)) return true;
    //    else {
    //      return {
    //        id: "unknown",
    //        data: { name: "Deleted Printer", printerState: "deleted" },
    //      };
    //    }
    //    return false;
    //  });

    const Jprinters = jobPrinterKeys.map((keyy) => {
      const tprinter = printers.find((printer) => printer.id === keyy);
      if (tprinter) {
        return tprinter;
      } else {
        return {
          id: "unknown",
          data: { name: "Deleted Printer", printerState: "deleted" },
        };
      }
    });

    setAllJobPrinters(Jprinters);
  }, [printers, jobPrinters]);

  const restartJobSinglePrinter = (printer) => {
    console.log("Restarting Job for printer:", printer);
    restartJobForPrinter(printer);
  };
  const stopJobSinglePrinter = (printer) => {
    console.log("Stopping Job for printer:", printer);
    stopJobForPrinter(printer);

    //  console.log("Stopping Job:", jobId);
    //  axios
    //    .post("auth/job/stop", { job: jobId }, config)
    //    .then((response) => {
    //      console.log("Job Data:", response.data);
    //      getAllJobsData();

    //      // setUserDataSync(true);

    //      // setUserData({ ...userData, printers: [response.data] });
    //    })
    //    .catch((error) => {
    //      // alert("Already Registered");
    //    });
  };

  const isPrinterOnline = (printer, jobPrinters) => {
    // console.log("PD::::",printer,Object.keys(jobPrinters).find((printerKey)=>printerKey===printer?.id));
    // const printerJobState =  jobPrinters.

    // console.log("JJJJJJJ::",jobPrinters[printer.id]);
    // console.log("JB KEYS::",Object.keys(jobPrinters));
    if (printer?.data?.updatedOn) {
      const currentTime = new Date(Date.now());
      const lastOnline = printer.data.updatedOn.toDate();
      const isOnline =
        (currentTime.getTime() - lastOnline.getTime()) / 1000 < 90;
      return (
        isOnline || jobPrinters[printer.id]?.printerState === "Downloading File"
      );
    }
    return false;
  };

  const getPrintTime = (mins) => {
    let minutes = parseInt(mins.split(" ")[0]);
    const hours = parseInt(minutes / 60);
    minutes = minutes % 60;
    // const hrs =
    return `${hours ? hours + "h" : ""} ${minutes}m`;
  };

  useEffect(() => {
    const printersUpdateHandler = setInterval(() => {
      setDummyFlag((flag) => !flag);
    }, 5000);

    return () => {
      clearInterval(printersUpdateHandler);
    };
  }, []);

  return (
    <div className="PrintersList">
      <span className="heading m400">Printers</span>
      <ul className="PrintersList__list">
        {allJobPrinters.map((printer, key) => {
          // console.log("PDDD:", printer);
          return (
            <li key={key} className="printerItem">
              <div className="printerNameStatus">
                {isPrinterOnline(printer, jobPrinters) && (
                  <div className="printerOnlineIndicator"></div>
                )}
                {!isPrinterOnline(printer, jobPrinters) && (
                  <div className="printerOfflineIndicator"></div>
                )}

                <span>{printer.data.name}</span>
                {jobState == "running" &&
                  isPrinterOnline(printer, jobPrinters) && (
                    <div className="printerSpecificControls">
                      {printer?.data?.printerState !== "PRINTING" && (
                        <div
                          className="iconControl"
                          onClick={() => restartJobSinglePrinter(printer.id)}
                        >
                          <IoRefresh />
                        </div>
                      )}
                      {printer?.data?.printerState === "PRINTING" && (
                        <div
                          className="iconControl"
                          onClick={() => stopJobSinglePrinter(printer.id)}
                        >
                          <IoStop />
                        </div>
                      )}
                    </div>
                  )}
              </div>
              {printer.data.printerState !== "deleted" &&
              isPrinterOnline(printer, jobPrinters) ? (
                <span className="pdata">
                  {jobState == "running" && printer?.data.printerState != "" ? (
                    <>
                      <span>{getPrintTime(printer.data.printTime)}</span>
                      {jobPrinters[printer.id].printerState ===
                        "Downloading File" && <span>Downloading</span>}
                      {jobPrinters[printer.id].printerState !==
                        "Downloading File" && (
                        <span>
                          {printer?.data.printerState[0]?.toUpperCase() +
                            printer?.data.printerState
                              ?.substring(1)
                              .toLowerCase() +
                            " (" +
                            printer.data.printProgress +
                            "%)"}
                        </span>
                      )}
                    </>
                  ) : printer?.data.printerState == "" ? (
                    <>
                      <span></span>
                      <span>Not yet initialized</span>
                    </>
                  ) : (
                    <>
                      <span></span>
                      <span>Print Finished</span>
                    </>
                  )}
                </span>
              ) : (
                <span>---</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default JobsPage;
