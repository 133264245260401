import React from "react";
import "./modal.css";

const Modal = (props) => {
  const handleModalClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div className="Modal" onClick={handleModalClick}>
      {props.children}
    </div>
  );
};

export default Modal;
