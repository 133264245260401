import React from "react";
import Modal from "../hoc/Modal/Modal";
import Button from "../New/Button/Button";
import "./userConfirmationBox.css";
const UserConfirmationBox = ({ onYes, onNo, onClose, displayText }) => {
  return (
    <Modal>
      <div className="UserConfirmationBox">
        <h2 className="s600">{displayText || "Are you sure ?"}</h2>
        <div className="actionButtons">
          <Button type="secondary" displayText="No" onClick={onNo} />
          <Button type="primary" displayText="Yes" onClick={onYes} />
        </div>
      </div>
    </Modal>
  );
};

export default UserConfirmationBox;
