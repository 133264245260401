import React from "react";
import { useState } from "react";
import LoginPage from "../LoginPage/LoginPage";
import UserDataFormPage from "../UserDataFormPage/UserDataFormPage";
import { Routes, Route, Link } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import HomePage from "../HomePage/HomePage";
// import UserDashboard from "../UserDashboard/UserDashboard";
// import UDashboard from "../UDashboard/UDashboard";
// import DashboardUI from "../DashboardUI/DashboardUI";
// import JobsUI from "../JobsUI/JobsUI";
import Printers from "../Printers/Printers";
import GcodeTest from "../GcodeTest/GcodeTest";
// import JobPreview from "../JobPreview/JobPreview";
import { showLoader, setUserData } from "../../redux/systemSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Dashboard from "../New/Dashboard/Dashboard";
import DashboardOld from "../Dashboard/Dashboard";
import { useEffect } from "react";
import Modal from "../hoc/Modal/Modal";
import "./layout.css";
import "./loader.css";
import JobsPage from "../New/JobsPage/JobsPage";
import Profile from "../New/Profile/Profile";
import TopNav from "../New/TopNav/TopNav";
import axios from "axios";
import { useFirebase } from "../Firebase/FirebaseContext";
import Onboarding from "../Onboarding/Onboarding";

const Layout = () => {
  let dispatch = useDispatch();
  const [newUser, setNewUser] = useState(false);
  const [loginComplete, setLoginComplete] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  // const [userData, setUserData] = useState({});
  const loaderEnabled = useSelector((state) => state.system.showLoader);
  // setTimeout(() => {
  // }, 1000);
  const { user } = useFirebase();

  const getUserData = () => {
    if (user.emailVerified) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": user.accessToken,
        },
      };
      console.log("Getting API User data");
      axios
        .get("/auth/user/", config)
        .then((response) => {
          console.log("API User data:", response.data);
          dispatch(setUserData(response.data));
          // setUserDataSync(true);
        })
        .catch((error) => {
          // alert("Already Registered");
          dispatch(setUserData({}));
        });
    }
  };
  useEffect(() => {
    if (user) {
      console.log("Getting user data ------------------");
      getUserData();
    }
  }, [user]);

  console.log("LOADER:", loaderEnabled, user);
  return (
    <>
      {loaderEnabled && (
        <Modal>
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Modal>
      )}
      <div className="mainContainer">
        <TopNav refereshUserData={getUserData} />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route path="/gcode" element={<GcodeTest />} />
          <Route
            path="/getting-started"
            element={
              <ProtectedRoute>
                <Onboarding refereshUserData={getUserData} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-ui"
            element={
              <ProtectedRoute>
                <Dashboard refereshUserData={getUserData} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-jobs-ui"
            element={
              <ProtectedRoute>
                <JobsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/printers"
            element={
              <ProtectedRoute>
                <Printers />
              </ProtectedRoute>
            }
          />
          {/* <Route
          path="/dashboard-ui"
          element={
            <ProtectedRoute>
              <DashboardUI
                hideSidebar={hideSidebar}
                setHideSidebar={setHideSidebar}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobs-ui"
          element={
            <ProtectedRoute>
              <JobsUI
                hideSidebar={hideSidebar}
                setHideSidebar={setHideSidebar}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/job/:jobID"
          element={
            <ProtectedRoute>
              <JobPreview
                hideSidebar={hideSidebar}
                setHideSidebar={setHideSidebar}
              />
            </ProtectedRoute>
          }
        />
       */}
          {/* old --- */}
          <Route
            path="/dashboard-all"
            element={
              <ProtectedRoute>
                <DashboardOld />
              </ProtectedRoute>
            }
          />
          {/* <Route
          path="/dashboard-custom"
          element={
            <ProtectedRoute>
              <UDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <UserDashboard />
            </ProtectedRoute>
          }
        /> */}

          <Route
            path="/welcome"
            element={
              <ProtectedRoute>
                <UserDataFormPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </>
    // <div>
    //   {!loginComplete ? (
    //     <LoginPage
    // setNewUser={(val) => setNewUser(val)}
    // setLoginComplete={(val) => setLoginComplete(val)}
    //     />
    //   ) : (
    //     ""
    //   )}

    //   {loginComplete && newUser ? (
    //     <UserDataFormPage setNewUser={(val) => setNewUser(val)} />
    //   ) : (
    //     ""
    //   )}
    //   {loginComplete && !newUser ? <Dashboard /> : ""}
    // </div>
  );
};

export default Layout;
