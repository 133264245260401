import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IoAdd, IoCheckmarkCircle, IoClose, IoRemove } from "react-icons/io5";
import FilePicker from "../New/FilePicker/FilePicker";
import PrinterPicker from "../New/PrinterPicker/PrinterPicker";
import "./createJobForm.css";
const CreateJobForm = ({
  onClose,
  onCreate,
  userFiles,
  onUpload,
  isUploading,
  userPrinters,
}) => {
  const [jobData, setJobData] = useState({});
  const [printQuantity, setPrintQuantity] = useState(1);
  const [jobMode, setJobMode] = useState("auto");
  const [startPrint, setStartPrint] = useState(true);
  const [jobFile, setJobFile] = useState({});
  const [validJobData, setValidJobData] = useState(false);
  const [jobName, setJobName] = useState("");

  const [selectedPrinters, setSelectedPrinters] = useState([]);
  // console.log("SP:", selectedPrinters);
  function handleDivClick(e) {
    e.stopPropagation();
  }

  const handlePrintQuantity = (e) => {
    console.log(e.target.value, parseInt(e.target.value));
    if (
      e.target.value == "" ||
      (e.target.value.indexOf("e") == -1 &&
        e.target.value.indexOf("-") == -1 &&
        e.target.value.indexOf(".") == -1 &&
        e.target.value > 0)
    ) {
      console.log("Valid value", e.target.value);
      setPrintQuantity(e.target.value);
    } else {
      setPrintQuantity(1);
      // console.log("INValid value", e.target.value);
    }
  };

  const adjustPrintQuantity = (direction) => {
    if (direction) {
      setPrintQuantity((quantity) => quantity + 1);
    } else {
      setPrintQuantity((quantity) => (quantity > 1 ? quantity - 1 : quantity));
    }
  };
  const handleJobMode = (mode) => {
    if (mode == "auto") {
    }
  };

  const handleJobCreation = () => {
    onCreate(jobData);
  };

  useEffect(() => {
    const updatedJobData = {
      printQuantity: printQuantity,
      jobMode: jobMode,
      startPrint: startPrint,
      jobFile: jobFile,
      jobName: jobName.trim(),
      selectedPrinters: jobMode == "auto" ? userPrinters : selectedPrinters,
    };
    // console.log("JOB UPDATES:", updatedJobData);
    // console.log(Object.keys(jobFile));
    // console.log(jobName != " " && jobName.length > 0 && printQuantity > 0);
    if (
      updatedJobData.jobName != "" &&
      updatedJobData.jobName.length > 0 &&
      updatedJobData.printQuantity > 0 &&
      Object.keys(updatedJobData.jobFile)?.length > 0 &&
      !!(
        updatedJobData.jobMode == "auto" ||
        (updatedJobData.jobMode != "auto" &&
          updatedJobData.selectedPrinters.length > 0)
      )
    ) {
      // console.log("VAlid data");
      setValidJobData(true);
    } else {
      // console.log("INVAlid data");
      setValidJobData(false);
    }

    setJobData(updatedJobData);
  }, [printQuantity, jobMode, startPrint, jobFile, jobName, selectedPrinters]);
  useEffect(() => {
    console.log("SPUP:", userPrinters);
  }, [userPrinters]);

  return (
    <div className="CreateJobForm" onClick={handleDivClick}>
      <div className="CreateJobForm__header">
        <h2 className="heading s600">Create Job</h2>
        <div className="closeButton" onClick={onClose}>
          <IoClose />
        </div>
      </div>
      <div className="CreateJobForm__content">
        <div className="inputField">
          <label className="required m300">Job Name</label>
          <input
            className="m400"
            type="text"
            name="jobName"
            id="jobName"
            placeholder="Enter Job Name"
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
          />
        </div>
        <div className="blankSpace" />
        <div className="inputField ">
          <label className="required m300">Print File</label>

          <FilePicker
            userFiles={userFiles}
            onUpload={onUpload}
            isUploading={isUploading}
            onFileSelect={setJobFile}
          />
        </div>
        <div className="inputField">
          <label className="required m300">Print Quantity</label>
          <div className="printQuantity">
            <input
              className="m400"
              type="number"
              name="printQuantity"
              id="printQuantity"
              placeholder="Enter print quantity"
              value={printQuantity}
              onChange={handlePrintQuantity}
              min={0}
            />
            <button
              disabled={printQuantity < 2}
              onClick={() => adjustPrintQuantity(false)}
            >
              <IoRemove />
            </button>
            <button onClick={() => adjustPrintQuantity(true)}>
              <IoAdd />
            </button>
          </div>
        </div>

        <div className="jobModeContainer">
          <label className="required m300">Job Mode</label>

          <div className="cardsContainer">
            <div
              className={[
                "modeCard",
                jobMode == "auto" ? "modeCard--selected" : "",
              ].join(" ")}
              onClick={() => setJobMode("auto")}
            >
              <span className="m400">
                Automatic Selection {jobMode == "auto" && <IoCheckmarkCircle />}
              </span>
              <p className="description r200">
                Printers will be automatically selected based on the
                availablilty
              </p>
            </div>
            <div
              className={[
                "modeCard",
                jobMode == "manual" ? "modeCard--selected" : "",
              ].join(" ")}
              onClick={() => setJobMode("manual")}
            >
              <span className="m400">
                Manual Selection {jobMode == "manual" && <IoCheckmarkCircle />}
              </span>
              <p className="description r200">
                Printers will be selected manually by the user
              </p>
            </div>
          </div>
        </div>
        {jobMode == "manual" && (
          <div className="inputField">
            <label className="required m300">Select Printer</label>

            <PrinterPicker
              selectedPrinters={selectedPrinters}
              onPrinterSelect={setSelectedPrinters}
              userPrinters={userPrinters}
            />
          </div>
        )}
      </div>

      <div className="CreateJobForm__footer">
        <div className="startPrint__container">
          <input
            type="checkbox"
            name="startPrint"
            id="startPrint"
            checked={startPrint}
            onChange={(e) => setStartPrint(e.target.checked)}
          />
          <span className="m300">
            Start printing immediately after creating the job
          </span>
        </div>
        <div className="actionButtons">
          <button className="Button Button--secondary" onClick={onClose}>
            Cancel
          </button>
          <button
            className="Button Button--primary"
            onClick={handleJobCreation}
            disabled={!validJobData}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateJobForm;
