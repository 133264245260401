import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IoChevronDown,
  IoChevronUp,
  IoCloudUpload,
  IoReload,
} from "react-icons/io5";
import Images from "../../../assets/images/images";
import "./FilePicker.css";

const FilePicker = ({
  userFiles,
  onUpload,
  isUploading,
  externalClick,
  setExternalClick,
  onFileSelect,
}) => {
  let fileUpload = useRef(null);
  let componentRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  // const [allFiles, setAllFiles] = useState(userFiles);
  const [expandSelector, setExpandSelector] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileElement, setFileElement] = useState(null);
  const selectFile = (file) => {
    console.log("USER SELECTED FILE:", file);
    setSelectedFile(file);

    setExpandSelector(false);
    onFileSelect(file);
  };
  const handleFileUploadClick = (e) => {
    e.stopPropagation();
    fileUpload.current.click();
  };

  const onFileUpload = (e) => {
    if (e.target.files[0]) {
      console.log(e, e.target.files[0], e.target.files[0].name);
      const newFile = { name: e.target.files[0].name };
      // setAllFiles((allFiles) => [newFile, ...allFiles]);
      // setSelectedFile(newFile);
      setUploadedFile(newFile);
      setFileUploading(true);
      onUpload(e);
    }
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    if (!isUploading) {
      setExpandSelector((state) => !state);
    }
  };

  useEffect(() => {
    if (fileUploading) {
      const targetFile = userFiles.filter(
        (file) => file.name == uploadedFile.name
      )[0];
      console.log("User uploaded file:", targetFile);
      setSelectedFile(targetFile);
      onFileSelect(targetFile);

      setFileUploading(false);
    }
    console.log("Files updated...");
  }, [userFiles]);

  useEffect(() => {
    if (isUploading == false) {
      console.log("Upload complete");
    }
  }, [isUploading]);

  //   const handleClickTarget = (e) => {
  //     console.log(e.target);
  //     if (e.target == componentRef.current) {
  //       console.log("SAme");
  //     } else {
  //       console.log("OUt");
  //     }
  //     console.log(e.target.parentElement, e.target.parentElement.nodeName);
  //   };

  //   useEffect(() => {
  //     document.addEventListener("mousedown", handleClickTarget);
  //   }, [document.removeEventListener("mousedown", handleClickTarget)]);

  useEffect(() => {
    var concernedElement = document.querySelector(".FilePicker");
    setFileElement(concernedElement);
  }, []);

  useEffect(() => {
    const checkOuterClick = (e) => {
      if (fileElement.contains(e.target)) {
      } else {
        setExpandSelector(false);
      }
    };

    if (fileElement) {
      window.addEventListener("mousedown", checkOuterClick);
    }
    return () => {
      window.removeEventListener("mousedown", checkOuterClick);
    };
  }, [fileElement]);

  return (
    <div
      ref={componentRef}
      className={[
        "FilePicker",
        "m400",
        isUploading ? "FilePicker--disabled" : "",
      ].join(" ")}
      onClick={handleDropdownClick}
    >
      <input
        className="m400"
        type="file"
        name="jobName"
        id="jobName"
        ref={fileUpload}
        onChange={onFileUpload}
        accept=".gcode,.gco"
      />
      <span className={selectedFile?.name ? "fileNameText" : "placeHolderText"}>
        {!isUploading
          ? !selectedFile?.name
            ? "Select File"
            : selectedFile.name
          : "Uploading File..."}
      </span>
      {!expandSelector && !isUploading && <IoChevronDown />}
      {expandSelector && !isUploading && <IoChevronUp />}
      {/* {isUploading && <IoReload />} */}

      {isUploading && <div className="lds-dual-ring"></div>}

      {expandSelector && (
        <div className="fileList">
          <ul className="customScroll" onClick={(e) => e.stopPropagation()}>
            {userFiles.map((file, key) => {
              let fileSize = file.size / 1024 / 1024;
              if (fileSize > 1) {
                fileSize = String(fileSize);
                fileSize =
                  fileSize.substring(0, fileSize.indexOf(".") + 3) + " MB";
              } else {
                fileSize = fileSize * 1024;
                fileSize = String(fileSize);
                fileSize =
                  fileSize.substring(0, fileSize.indexOf(".") + 3) + " kB";
              }
              return (
                <li key={key} onClick={() => selectFile(file)}>
                  {/* <div className="fileImagePreview"> */}
                  <img src={Images.logo} />
                  {/* </div> */}
                  {`${file.name} (${fileSize})`}
                </li>
              );
            })}

            {!userFiles.length && (
              <li className="defaultOption">
                <span>No Files to Select</span>
              </li>
            )}
          </ul>
          <div
            className="uploadContainer"
            onClick={(e) => {
              handleFileUploadClick(e);
              e.stopPropagation();
            }}
          >
            <IoCloudUpload />
            <span>Upload new file</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilePicker;
