import React, { useEffect } from "react";
import {
  IoAdd,
  IoAddCircle,
  IoAddCircleOutline,
  IoFileTrayFull,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserPrinters,
  showCreateJobForm,
  showLoader,
} from "../../../redux/systemSlice";
import "./overview.css";
import { useState } from "react";
import Modal from "../../hoc/Modal/Modal";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import { useFirebase } from "../../Firebase/FirebaseContext";
import axios from "axios";

const Overview = ({ refereshUserData }) => {
  const { user, getAllPrintersData } = useFirebase();
  let dispatch = useDispatch();
  const userPrinters = useSelector((state) => state.system.userPrinters);
  const userJobs = useSelector((state) => state.system.userJobs);
  const userData = useSelector((state) => state.system.userData);
  const [showAddPrinterModal, setShowAddPrinterModal] = useState(false);
  const [printerName, setPrinterName] = useState("");
  const [printerAdded, setPrinterAdded] = useState(false);
  const [printerID, setPrinterID] = useState("");

  useEffect(() => {}, [userPrinters, userJobs, userData]);
  const handleShowCreateJobForm = () => {
    dispatch(showCreateJobForm(true));
  };

  const handleAddNewPrinterAction = () => {
    console.log("Adding new printer");
    setShowAddPrinterModal(true);
  };
  const closeModal = () => {
    setShowAddPrinterModal(false);
    setPrinterAdded(false);
  };

  const getAllPrinters = () => {
    getAllPrintersData().then((printers) => {
      let allPrinters = [];
      printers.forEach((printer) => {
        allPrinters.push({ id: printer.id, data: printer.data() });
      });
      console.debug("ALL PRINTERS:", allPrinters);
      // setPrinters(allPrinters);
      dispatch(setUserPrinters(allPrinters));
    });
  };

  const addNewPrinter = () => {
    console.log("PRINTER NAME:", printerName);
    var similarPrinters = userPrinters.filter(
      (printer) => printer.data.name == printerName
    );
    // console.log(similarPrinters, similarPrinters.length);
    // return;
    if (printerName !== "") {
      if (similarPrinters.length > 0) {
        alert("You already have a printer with the same name !");
        return;
      }
      // if (newPrinterName.current.value) {
      // setAddingPrinter(false);
      dispatch(showLoader(true));
      const headers = {
        "Content-Type": "application/json",
        "X-Auth-Token": user.accessToken,
      };
      axios
        .post("auth/printers/add", { name: printerName }, { headers: headers })
        .then((response) => {
          dispatch(showLoader(false));

          console.log("Printers Data:", response.data);
          // closeModal();
          getAllPrinters();
          setPrinterID(response.data.id);

          // setPrinterID(response.data.id);
          // setUserDataSync(true);
          // setCurrentStep((currentStep) => currentStep + 1);
          // setUserData({ ...userData, printers: [response.data] });
        })
        .catch((error) => {
          dispatch(showLoader(false));
          console.log(error);

          // alert("Already Registered");
        });
    } else {
      alert("Printer name cannot be blank");
    }
  };

  useEffect(() => {
    if (!printerAdded && printerID != "") {
      setPrinterAdded(true);

      setTimeout(() => {
        downloadPrinterConfig();
      }, 1000);
    }
  }, [printerID]);

  const downloadPrinterConfig = () => {
    console.log("Downloading Config file", printerName);

    const deviceConfig = {
      id: printerID,
      name: printerName,
      serverURL: "https://asia-south1-cloud-3d-print-359413.cloudfunctions.net",
      apiKey: userData.apiKey,
      wifiSSID: userData.wifiSSID,
      wifiPassword: userData.wifiPassword,
    };
    console.log("Printer Data::", deviceConfig);
    // const blob = await deviceConfig.blob(); // blob just as yours
    // const href = await URL.createObjectURL(blob);

    const fileName = "config.json";
    const json = JSON.stringify(deviceConfig, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const resetAddNewPrinter = () => {
    setPrinterAdded(false);
  };

  useEffect(() => {}, [printerName, userPrinters]);

  return (
    <div className="Overview">
      <OverviewCard
        buttonIcon={<IoAddCircleOutline />}
        headingIcon={<IoFileTrayFull />}
        buttonName="Create Job"
        cardHeading="Total Jobs"
        cardValue={userJobs?.length}
        buttonAction={handleShowCreateJobForm}
      />
      <OverviewCard
        buttonIcon={<IoAddCircleOutline />}
        headingIcon={<IoFileTrayFull />}
        buttonName="Add Printer"
        cardHeading="Printers"
        cardValue={userPrinters?.length}
        buttonDisabled={
          userPrinters?.length >= userData?.subscription?.maxDevices
        }
        buttonAction={handleAddNewPrinterAction}
      />
      {showAddPrinterModal && (
        <Modal>
          {!printerAdded ? (
            <>
              <div className="addNewPrinter">
                <h2 className="header s600">Add Printer</h2>
                <InputField
                  labelName="Printer Name"
                  required={true}
                  inputMaxLength={24}
                  inputPlaceHolder={"Your awsome printer"}
                  inputValue={printerName}
                  onChange={(value) => setPrinterName(value)}
                />
                <div className="actionButtons">
                  <Button
                    displayText="Cancel"
                    type="secondary"
                    onClick={closeModal}
                  />
                  <Button
                    enabled={printerName.length > 0}
                    displayText="Done"
                    onClick={addNewPrinter}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="downloadConfig">
                <h2 className="header s600">Printer Added !</h2>
                <p className="r300">
                  The <b>config.json</b> file is being downloaded. If the
                  download doesn't start, click{" "}
                  <span
                    className="linkText r300"
                    onClick={downloadPrinterConfig}
                  >
                    here
                  </span>{" "}
                  to download it manually.
                </p>
                <div className="actionButtons">
                  <Button
                    displayText="Close"
                    type="secondary"
                    onClick={closeModal}
                  />
                  <Button
                    enabled={
                      userPrinters.length < userData.subscription.maxDevices
                    }
                    displayText="Add New"
                    onClick={resetAddNewPrinter}
                  />
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

const OverviewCard = ({
  buttonName,
  buttonIcon,
  buttonAction,
  cardHeading,
  headingIcon,
  cardValue,
  buttonDisabled,
}) => {
  return (
    <div className="OverviewCard">
      <div className="OverviewCard__details">
        <div className="heading">
          {headingIcon}
          <span className="r500">{cardHeading}</span>
        </div>
        <span className="value s1000">{cardValue}</span>
      </div>
      <div className="OverviewCard__actions">
        <button
          className="button--primarywithIcon"
          onClick={buttonAction}
          disabled={buttonDisabled}
        >
          {buttonIcon}
          {buttonName}
        </button>
      </div>
    </div>
  );
};

export default Overview;
