import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: "",
    email: "",
    userId: "",
    googleUserId: "",
    profileIcon: "",
  },
  reducers: {
    updateData: (state, action) => {
      console.log(action.payload);
      state = action.payload;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateUserId: (state, action) => {
      state.userId = action.payload;
    },
    updateGoogleUserId: (state, action) => {
      state.googleUserId = action.payload;
    },
    updateProfileIcon: (state, action) => {
      state.profileIcon = action.payload;
    },
  },
});

export const {
  updateData,
  updateName,
  updateEmail,
  updateUserId,
  updateGoogleUserId,
  updateProfileIcon,
} = userSlice.actions;

export default userSlice.reducer;
