import React from "react";
const Button = (props) => {
  const buttonType = props.type ? props.type : "primary";

  return (
    <button
      className={`Button Button--${buttonType}`}
      onClick={props.onClick || null}
      disabled={
        props.enabled !== null && props.enabled !== undefined
          ? !props.enabled
          : false
      }
    >
      {props.icon ? props.icon : null}
      {props.displayText || "Click"}
    </button>
  );
};

export default Button;
