import React, { useEffect } from "react";
import "./files.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoDownload, IoPencil, IoTrash } from "react-icons/io5";
import { useFirebase } from "../Firebase/FirebaseContext";
import UserConfirmationBox from "../UserConfirmationBox/UserConfirmationBox";
import { showLoader } from "../../redux/systemSlice";
const Files = ({ onRefreshFiles }) => {
  const [showUserConfirmationBox, setShowUserConfirmationBox] = useState(false);
  let dispatch = useDispatch();
  const { deleteFile } = useFirebase();
  const [selectedFile, setSelectedFile] = useState(null);
  const userFiles = useSelector((state) => state.system.userFiles);
  const handleDeleteFileAction = (file) => {
    setSelectedFile(file.name);
    console.log("User is deleting File: ", file.name);
    setShowUserConfirmationBox(true);
  };

  const confirmDeleteFile = () => {
    deleteFile(selectedFile)
      .then((res) => {
        console.log(res);
        onRefreshFiles();
        dispatch(showLoader(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [allFiles, setAllFiles] = useState([]);
  useEffect(() => {
    console.log("UF updates: ", userFiles);
  }, [userFiles]);

  return (
    <div className="Files">
      {showUserConfirmationBox && (
        <UserConfirmationBox
          displayText={`Are you sure you want to delete the file - "${selectedFile}" ?`}
          onYes={() => {
            console.log("User pressed yes");
            dispatch(showLoader(true));
            confirmDeleteFile();
            setShowUserConfirmationBox(false);
          }}
          onNo={() => setShowUserConfirmationBox(false)}
        />
      )}
      <div className="Files__mainContainer">
        <div className="Files__content">
          <ul className="Files__content__list">
            {userFiles != null &&
              userFiles.map((file, key) => {
                return (
                  <li key={key} className="fileItem">
                    <div className="fileDetails">
                      <span>
                        {key + 1}) {file.name} &nbsp;&nbsp;&nbsp; (
                        {Math.round((file.size / 1024 / 1024) * 100) / 100} MB)
                      </span>
                    </div>
                    <ul className="subMenu">
                      <li>
                        <IoDownload />
                      </li>

                      <li onClick={() => handleDeleteFileAction(file)}>
                        <IoTrash />
                      </li>
                    </ul>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Files;
