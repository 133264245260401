import React, { useEffect, useRef } from "react";

import { initializeApp } from "firebase/app";
import { useFirebase } from "../Firebase/FirebaseContext";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { showLoader } from "../../redux/systemSlice";
import "./dashboardold.css";

const Dashboard = () => {
  let dispatch = useDispatch();

  let navigate = useNavigate();
  const {
    logOut,
    user,
    getPrinters,
    listenPrinters,
    printersData,
    getAllStorageFiles,
    uploadStorageFile,
    getFileMetaData,
    getAllJobs,
    deleteFile,
    listenJob,
    liveJobsData,
  } = useFirebase();

  const [userData, setUserData] = useState(undefined);
  const [userPrintersData, setUserPrintersData] = useState(undefined);
  const [userFiles, setUserFiles] = useState([]);
  const [userJobs, setUserJobs] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [printersToSelect, setPrintersToSelect] = useState([]);
  const [printersSelectedForJob, setPrintersSelectedForJob] = useState([]);
  const [fileSelectedForJob, setFileSelectedForJob] = useState(undefined);

  const [userToken, setUserToken] = useState(undefined);
  const [apiKeyUpdate, setApiKeyUpdate] = useState(false);
  const [userDataSync, setUserDataSync] = useState(false);

  const [addingPrinter, setAddingPrinter] = useState(false);
  const [quantityPrinting, setQuantityPrinting] = useState(false);
  const [printQuantity, setPrintQuantity] = useState(1);
  const newPrinterName = useRef("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": user.accessToken,
    },
  };
  const signOutUser = () => {
    logOut().then((data) => {
      console.log("USer Logged out");
      navigate("/");
    });
  };

  const handlePrinterForJob = (event, printer) => {
    console.log(event.target.checked, printer);
    let selectedPrinters = printersSelectedForJob;
    if (event.target.checked) {
      selectedPrinters.push(printer);
    } else {
      selectedPrinters = selectedPrinters.filter(
        (pitem) => pitem.id != printer.id
      );
    }

    setPrintersSelectedForJob([...selectedPrinters]);
    // const updatedPrinters =
  };

  const handleFileForJob = (event) => {
    console.log(event.target.value);

    setFileSelectedForJob(
      userFiles.find((file) => file.name == event.target.value)
    );
    // const updatedPrinters =
  };

  // const newPrinterData = { printerName: "" };

  const getPrintersData = () => {
    // axios
    //   .get("auth/printers/all", config)
    //   .then((response) => {
    //     console.log("Printers Data:", response.data);
    //     console.log("CUD::", userData);
    //     const updatedData = { ...userData, printers: response.data };
    //     console.log("UD:", updatedData);
    //     setUserData(updatedData);
    //   })
    //   .catch((error) => {
    //     // alert("Already Registered");
    //   });
    getPrinters();
    //   getPrinters()
    //     .then((data) => {
    //       let allPrinters = [];
    //       data.forEach((printer) => {
    //         console.log("PRD:", printer.data());
    //         allPrinters.push({
    //           id: printer.id,
    //           data: printer.data(),
    //         });
    //       });

    //       const updatedData = { ...userData, printers: allPrinters };
    //       setUserData(updatedData);
    //       setUserDataSync(false);
    //       listenPrinters();
    //     })
    //     .catch((err) => {
    //       console.log("ERR:", err);
    //     });
  };
  const startJob = (jobId) => {
    console.log("Starting Job:", jobId);
    axios
      .post("auth/jobs/start", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);

        getAllJobsData();
        listenJob(jobId);

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };
  const stopJob = (jobId) => {
    console.log("Stopping Job:", jobId);
    axios
      .post("auth/jobs/stop", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);
        getAllJobsData();

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const pauseJob = (jobId) => {
    console.log("Pausing Job:", jobId);
    axios
      .post("auth/jobs/pause", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);
        getAllJobsData();

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };
  const resumeJob = (jobId) => {
    console.log("Resuming Job:", jobId);
    axios
      .post("auth/jobs/resume", { job: jobId }, config)
      .then((response) => {
        console.log("Job Data:", response.data);
        getAllJobsData();

        // setUserDataSync(true);

        // setUserData({ ...userData, printers: [response.data] });
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const getAllJobsData = () => {
    getAllJobs()
      .then((jobsData) => {
        let jobs = [];
        jobsData.forEach((job) => {
          jobs.push(job.data());
        });
        console.log("JOBS::", jobs);
        setUserJobs(jobs);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get("auth/jobs/all", config)
    //   .then((response) => {
    //     console.log("Jobs Data:", response.data);
    //     // console.log("CUD::", userData);
    //     // const updatedData = { ...userData, printers: response.data };
    //     // console.log("UD:", updatedData);
    //     // setUserData(updatedData);
    //   })
    //   .catch((error) => {
    //     // alert("Already Registered");
    //   });
  };

  const addNewPrinter = () => {
    if (newPrinterName.current.value) {
      setAddingPrinter(false);
      axios
        .post(
          "auth/printers/add",
          { name: newPrinterName.current.value },
          config
        )
        .then((response) => {
          console.log("Printers Data:", response.data);
          // setUserDataSync(true);

          // setUserData({ ...userData, printers: [response.data] });
        })
        .catch((error) => {
          // alert("Already Registered");
        });
    } else {
      alert("Enter the printer Name");
    }
  };

  const identifyPrinter = (printerID) => {
    axios
      .get(`/auth/printers/identify?id=${printerID}`, config)
      .then((response) => {
        console.log(response.data);
        //  setUserData(response.data);
        //  setUserDataSync(true);
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const getUserData = () => {
    axios
      .get("/auth/user/", config)
      .then((response) => {
        console.log(response.data);
        setUserData(response.data);
        setUserDataSync(true);
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const generateAPIKey = () => {
    setApiKeyUpdate(true);
    axios
      .get("/auth/apikey/generate", config)
      .then((response) => {
        console.log(response.data);
        const udpateUserData = { ...userData, apiKey: response.data.apiKey };
        setUserData(udpateUserData);
        setApiKeyUpdate(false);
      })
      .catch((error) => {
        // alert("Already Registered");
      });
  };

  const renewAPIKey = () => {
    setApiKeyUpdate(true);

    if (window.confirm("Are you sure ?") == true) {
      setApiKeyUpdate(true);
      axios
        .get("/auth/apikey/renew", config)
        .then((response) => {
          console.log(response.data);
          const udpateUserData = { ...userData, apiKey: response.data.apiKey };
          setUserData(udpateUserData);
          setApiKeyUpdate(false);
        })
        .catch((error) => {
          // alert("Already Registered");
        });
    } else {
      setApiKeyUpdate(false);
    }
  };

  const downloadConfig = async (printer) => {
    const deviceConfig = {
      id: printer.id,
      name: printer.data.name,
      apiKey: userData.apiKey,
    };
    console.log("Printer Data::", deviceConfig);
    // const blob = await deviceConfig.blob(); // blob just as yours
    // const href = await URL.createObjectURL(blob);

    const fileName = "config.json";
    const json = JSON.stringify(deviceConfig, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const udpatePrinterStatus = () => {
    console.log("Refreshing Printer Data");
    // console.log(printersData);
    if (printersData) {
      const currentTime = new Date(Date.now());

      let incomingPrintersData = printersData.map((printer) => {
        const printerUpdateTime = printer.data.updatedOn.toDate();
        // console.log(
        //   currentTime.getTime(),
        //   printerUpdateTime.getTime(),
        //   (currentTime.getTime() - printerUpdateTime.getTime()) / 1000
        // );
        if ((currentTime.getTime() - printerUpdateTime.getTime()) / 1000 < 1800)
          printer["online"] = true;
        else printer["online"] = false;

        return printer;
      });
      // return incomingPrintersData;

      setUserPrintersData(incomingPrintersData);
    }
  };

  const processFile = (file) => {
    return new Promise((resolve, reject) => {
      console.log("Reading FIle:", file);
      let reader = new FileReader();
      let buildSummaryObject = {};

      // Closure to capture the file information.
      reader.onload = function (fileData) {
        console.log(fileData);
        const fileBuildSummary =
          fileData.target.result.split("Build Summary")[1];
        if (fileBuildSummary) {
          console.log("File Buld summary is present");
          console.log("Build Summary:", fileBuildSummary);

          let plasticWeight = String(
            fileBuildSummary.split("Plastic weight: ")[1]
          );
          buildSummaryObject["plasticWeightGms"] = plasticWeight.substring(
            0,
            plasticWeight.indexOf(" ")
          );
          buildSummaryObject["plasticWeightLb"] = plasticWeight.substring(
            plasticWeight.indexOf("(") + 1,
            plasticWeight.indexOf(" lb")
          );

          let buildTime = String(fileBuildSummary.split("Build time: ")[1]);
          buildSummaryObject["buildTimeHrs"] = buildTime.substring(
            0,
            buildTime.indexOf(" ")
          );
          buildSummaryObject["buildTimeMins"] = buildTime.substring(
            buildTime.indexOf("hours ") + 6,
            buildTime.indexOf(" minutes")
          );

          let filamentLength = String(
            fileBuildSummary.split("Filament length: ")[1]
          );
          buildSummaryObject["filamentLengthMm"] = filamentLength.substring(
            0,
            filamentLength.indexOf(" ")
          );
          buildSummaryObject["filamentLengthM"] = filamentLength.substring(
            filamentLength.indexOf("(") + 1,
            filamentLength.indexOf(" m)")
          );

          let plasticVolume = String(
            fileBuildSummary.split("Plastic volume: ")[1]
          );
          buildSummaryObject["plasticVolumeMm3"] = plasticVolume.substring(
            0,
            plasticVolume.indexOf(" ")
          );
          buildSummaryObject["plasticVolumeCc"] = plasticVolume.substring(
            plasticVolume.indexOf("(") + 1,
            plasticVolume.indexOf(" cc)")
          );
          if (
            Object.keys(buildSummaryObject).filter(
              (item) =>
                buildSummaryObject[item] == undefined ||
                buildSummaryObject[item] == ""
            ).length
          ) {
            console.log("Unknown Buld summary is present");
            buildSummaryObject = { invalid: true, summary: fileBuildSummary };
          } else {
            console.log("Build summary extracted successfully");
            buildSummaryObject = { invalid: false, ...buildSummaryObject };
          }
          console.log("Data:", buildSummaryObject);
          resolve(buildSummaryObject);
        } else {
          console.log("No Buld summary is present");
          resolve(buildSummaryObject);
        }

        // return function (e) {
        //   // jQuery("#ms_word_filtered_html").val(e.target.result);
        // };
      };

      // Read in the image file as a data URL.
      reader.readAsText(file);
    });
  };

  const onFileChange = (event) => {
    // Update the state
    console.log(event.target.files[0]);
    setUploading(true);

    // setFileToUpload(event.target.files[0]);

    // uploadStorageFile(event.target.files[0]).then((data) => {
    //   console.log(data);
    //   setTimeout(() => {
    //     updateUserFiles();
    //   }, 1000);
    // });
    processFile(event.target.files[0]).then((metadata) => {
      console.log("Uploading File", metadata);
      uploadStorageFile(event.target.files[0], metadata)
        .then((data) => {
          console.log("Done:", data);
          setTimeout(() => {
            updateUserFiles();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const deleteStorageFile = (fileName) => {
    deleteFile(fileName).then((res) => {
      console.log(res);
      let updateUserFiles = userFiles.filter((file) => file.name != fileName);
      setUserFiles(updateUserFiles);
      alert("File Deleted");
    });
  };
  const updateUserFiles = () => {
    getAllStorageFiles().then(async (files) => {
      // console.log("USER FILEs:", files);
      let allFiles = [];
      // await files.items.forEach(async (item) => {

      for (let i = 0; i < files.items.length; i++) {
        // console.log("GEting file");

        let fileData = await getFileMetaData(files.items[i]);
        await fileData;
        // console.log("FILE INFO::", fileData);
        allFiles.push({
          name: fileData.name,
          size: fileData.size,
          created: fileData.timeCreated,
          metadata: fileData.customMetadata,
        });
      }
      // });

      console.log("FD:", allFiles);
      // files.items.forEach((element) => {
      //   console.log(element, element.name);
      // });
      // console.log("Updateing", allFiles);
      setUserFiles(allFiles);
      setUploading(false);
    });
  };

  const submitNewJob = () => {
    console.log(
      "Selcted Printers for JOB::",
      printersSelectedForJob.length,
      printersSelectedForJob
    );

    if (fileSelectedForJob.name) {
      const jobData = {
        jobName: "JOB-" + Math.round(Date.now() / 1000),
        jobPrinters: printersSelectedForJob.map((printer) => printer.id),
        jobFile: fileSelectedForJob.name,
        jobFileMetadata: fileSelectedForJob.metadata,
        jopPrintQuantity: printQuantity,
      };
      console.log("SUBMITTING JOB::", jobData);

      axios
        .post("auth/jobs/add", jobData, config)
        .then((response) => {
          setPrintersToSelect([]);
          console.log("Printers Data:", response.data);
          // setUserDataSync(true);
          getAllJobsData();
          // setUserData({ ...userData, printers: [response.data] });
        })
        .catch((error) => {
          // alert("Already Registered");
        });
    }
  };

  const addJob = () => {
    const availablePrinters = userPrintersData?.filter((item) => {
      if (item.online == true && item.data.printerState == "READY") {
        return true;
      }
      return false;
    });
    console.log("PRINTERS AVAILABLE::", availablePrinters);

    if (availablePrinters.length > 0) {
      console.log("PRINTERS AVAILABLE::", availablePrinters);
      setPrintersToSelect(availablePrinters);
    } else {
      alert("No Printers available");
    }
  };

  useEffect(() => {
    getUserData();
    getPrinters();
    getAllJobsData();
    updateUserFiles();
    dispatch(showLoader(false));
  }, []);

  useEffect(() => {
    // const updatedData = { ...userData, printers: printersData };
    // console.log("PUD:", updatedData);
    // setUserData(updatedData);
    let statusHandler;
    if (printersData) {
      let incomingPrintersData = printersData;
      incomingPrintersData = udpatePrinterStatus(incomingPrintersData);
      udpatePrinterStatus();

      statusHandler = setInterval(() => {
        // console.log("Upating Status");
        // console.log("Printers Data", printersData);
        udpatePrinterStatus();
      }, 10000);
      // console.log("IP:", incomingPrintersData);
      // setUserPrintersData(incomingPrintersData);
    }
    return () => {
      clearInterval(statusHandler);
    };
  }, [printersData]);

  // useEffect(() => {

  //     getPrintersData();
  //   }
  // }, [userDataSync]);

  useEffect(() => {
    console.log("JOB Updates:", liveJobsData);
    let jobUpdates = userJobs;

    liveJobsData.forEach((job) => {
      jobUpdates = jobUpdates.map((jobData) => {
        if (jobData.jobID == job.jobID) {
          jobData = { ...job };
        }
        return jobData;
      });
    });
    setUserJobs(jobUpdates);
  }, [liveJobsData]);

  return (
    <div className="DashboardBody">
      <section>
        <h1>Dashboard</h1>
        {user && <h1>Hi {user.displayName}</h1>}
        <button className="button--primary" onClick={signOutUser}>
          Signout
        </button>
      </section>
      <hr></hr>
      {userData && (
        <>
          <section>
            <div>
              <h2>Devices Dashboard</h2>
              <h3>Total Devices: {userPrintersData?.length} </h3>
              <h3>
                Total Online Devices:
                {userPrintersData?.filter((item) => item.online == true).length}
              </h3>
            </div>
          </section>
          <hr></hr>
          <section>
            {userData.subscription && (
              <>
                <h2>Subscription Plan</h2>
                <span>
                  Plan Name: <b>{userData.subscription.planName}</b>
                </span>
                <br></br>
                <span>
                  Start Date:{" "}
                  <b>{new Date(userData.subscription.start).toDateString()}</b>
                </span>
                <br></br>
                <span>
                  Expires on:{" "}
                  <b>
                    {userData.subscription.end
                      ? userData.subscription.end
                      : "No Expiry"}
                  </b>
                </span>
                <br></br>

                <span>
                  Max Devices: <b>{userData.subscription.maxDevices}</b>
                </span>
              </>
            )}
          </section>
          <br></br>
          <hr></hr>
          <section>
            <h2>Settings</h2>
            <span>
              API Key:{" "}
              <b>{userData.apiKey ? userData.apiKey : "<Not yet Generated>"}</b>
            </span>
            <br></br>
            <br></br>
            {userData.apiKey ? (
              <button disabled={apiKeyUpdate} onClick={renewAPIKey}>
                Renew API Key
              </button>
            ) : (
              <button disabled={apiKeyUpdate} onClick={generateAPIKey}>
                Generate API Key
              </button>
            )}
          </section>
          <br></br>
          <hr></hr>
          <section>
            <h2>Your Printers </h2>
            {userPrintersData != undefined && userPrintersData.length > 0 && (
              <ol>
                {userPrintersData.map((printer, key) => {
                  return (
                    <div key={key}>
                      <li>
                        <span>{`${printer.data.name} ${
                          printer.online ? "🟢" : "🔴"
                        }`}</span>
                        &nbsp; &nbsp;
                        <button onClick={() => identifyPrinter(printer.id)}>
                          Identify
                        </button>
                        {printer.data.printState == "PRINTING" && (
                          <progress
                            value={printer.data.printProgress}
                            min="0"
                            max="100"
                          >
                            {printer.data.printProgress}
                          </progress>
                        )}
                        <ul>
                          {Object.keys(printer.data).map((item, key) => {
                            if (
                              [
                                "macAddress",
                                "printProgress",
                                "printState",
                                "printerState",
                                "printTime",
                                "updatedOn",
                              ].includes(item)
                            ) {
                              if (item == "updatedOn") {
                                return (
                                  <li key={key}>{`${item} : ${new Date(
                                    printer.data[item].toDate()
                                  ).toDateString()} ${new Date(
                                    printer.data[item].toDate()
                                  ).toLocaleTimeString()}`}</li>
                                );
                              }
                              if (item == "printProgress") {
                                return (
                                  <li key={key}>
                                    <b>{`${item} : ${printer.data[item]} %`}</b>
                                  </li>
                                );
                              }
                              if (item == "printTime") {
                                return (
                                  <li key={key}>
                                    <b>{`${item} : ${parseInt(
                                      parseFloat(printer.data[item]) / 60
                                    )} hrs ${parseInt(
                                      parseFloat(printer.data[item]) % 60
                                    )} mins `}</b>
                                  </li>
                                );
                              }
                              return (
                                <li
                                  key={key}
                                >{`${item} : ${printer.data[item]}`}</li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                      <br></br>
                      {/* <li>{`${printer.data.name} -> ${
                        printer.data.printProgress || 0
                      }%`}</li> */}

                      <button onClick={() => downloadConfig(printer)}>
                        Download Config File
                      </button>
                      <br></br>
                      <br></br>
                    </div>
                  );
                })}
              </ol>
            )}
            {userPrintersData != undefined &&
              userData.subscription.maxDevices > userPrintersData.length && (
                <button
                  onClick={() => {
                    if (!addingPrinter) {
                      setAddingPrinter(true);
                    } else {
                      addNewPrinter();
                    }
                  }}
                >
                  {!addingPrinter ? "Add Printer" : "Submit"}
                </button>
              )}

            {addingPrinter && (
              <>
                <input
                  type="text"
                  name="printerName"
                  id="printerName"
                  placeholder="Printer Name"
                  ref={newPrinterName}
                />
              </>
            )}
          </section>
          <hr></hr>
          <section>
            <h2>Your Files </h2>

            {userFiles.length ? (
              <ol>
                {userFiles.map((file, key) => {
                  return (
                    <li key={key}>
                      {`${file.name}  (${
                        file.size / 1024 > 1000
                          ? `${
                              Math.round((file.size / 1024 / 1024) * 100) / 100
                            } MB`
                          : `${Math.round((file.size / 1024) * 100) / 100} kB`
                      })`}
                      &nbsp;
                      <button onClick={() => deleteStorageFile(file.name)}>
                        Delete
                      </button>
                    </li>
                  );
                })}
              </ol>
            ) : (
              ""
            )}
            <h3>Upload a File</h3>
            <span>Note: Only .gcode file are supported</span>
            <br></br>
            {!uploading ? (
              <input
                onChange={onFileChange}
                type="file"
                name="uploadedfile"
                id="uploadedfile"
                accept=".gcode,.gco"
              />
            ) : (
              ""
            )}
          </section>
          <hr></hr>
          <section>
            <h2>Your Jobs</h2>
            <div>
              <button onClick={addJob}>Create a New Job</button>
              {printersToSelect.length ? (
                <button onClick={() => setPrintersToSelect([])}>Cancel</button>
              ) : (
                ""
              )}
              <br></br>

              <br></br>
              {printersToSelect.length ? (
                <>
                  <label htmlFor="quantityPrint">Quantity Print : </label>
                  <input
                    type="checkbox"
                    id="quantityPrint"
                    onChange={(event) =>
                      setQuantityPrinting(event.target.checked)
                    }
                  ></input>
                  <br></br>
                  {quantityPrinting ? (
                    <>
                      <label htmlFor="printQuantity">Quantity : </label>

                      <input
                        onChange={(e) => setPrintQuantity(e.target.value)}
                        value={printQuantity}
                        type="number"
                        name="printQuantity"
                        id="printQuantity"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  <br></br>
                  <div>
                    <span>Available Printers:</span>
                    {printersToSelect.map((printer, key) => {
                      return (
                        <div key={key}>
                          <input
                            type="checkbox"
                            name="pSelect"
                            id={"ps" + key}
                            value={printer.data.name}
                            onChange={(event) =>
                              handlePrinterForJob(event, printer)
                            }
                          />

                          <label htmlFor={"ps" + key}>
                            {printer.data.name}
                          </label>
                          <br></br>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <span>Select File to print:</span>
                    <select onChange={handleFileForJob}>
                      <option disabled selected={true}>
                        Select a File
                      </option>
                      {userFiles.map((file, key) => {
                        return (
                          <option key={key} value={file.name}>
                            {file.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    onClick={submitNewJob}
                    disabled={
                      !printersSelectedForJob.length || !fileSelectedForJob
                    }
                  >
                    Submit Job
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              <h4>Your Jobs</h4>
              <ol>
                {userJobs.map((job, key) => {
                  return (
                    <li key={key}>
                      <div>
                        <span>
                          <b>{job.jobName}</b>
                        </span>
                        &nbsp; &nbsp; &nbsp;
                        {job.jobState == "created" ? (
                          <button onClick={() => startJob(job.jobID)}>
                            Start Job
                          </button>
                        ) : (
                          ""
                        )}
                        {job.jobState == "running" ? (
                          <>
                            <button onClick={() => stopJob(job.jobID)}>
                              Stop Job
                            </button>
                            <button onClick={() => pauseJob(job.jobID)}>
                              Pause Job
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                        {job.jobState == "stopped" ||
                        job.jobState == "finished" ? (
                          <button onClick={() => startJob(job.jobID)}>
                            Re-Run Job
                          </button>
                        ) : (
                          ""
                        )}
                        {job.jobState == "paused" ? (
                          <button onClick={() => resumeJob(job.jobID)}>
                            Resume Job
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <span>Job Details:</span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Job State: <b>{job.jobState}</b>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Print Quantity: <b>{job.totalQuantity}</b>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Printed Quantity: <b>{job.printedQuantity}</b>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          In-print Quantity: <b>{job.inPrintQuantity}</b>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Created on:{" "}
                          <b>
                            {new Date(job.createdOn.toDate()).toLocaleString()}
                          </b>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Job File:
                          <b>{job.jobFile}</b>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Job Metadata:
                          <ul>
                            {Object.keys(job.jobFileMetadata).map(
                              (keyy, key) => {
                                return (
                                  <li
                                    key={key}
                                  >{`${keyy}: ${job.jobFileMetadata[keyy]}`}</li>
                                );
                              }
                            )}
                          </ul>
                        </span>
                        <br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          Job Printers:
                          <ol>
                            {userPrintersData
                              ? Object.keys(job.jobPrinters).map(
                                  (keyyy, key) => {
                                    if (
                                      userPrintersData
                                        .map((pp) => pp.id)
                                        .includes(
                                          job.jobPrinters[keyyy].printerID
                                        )
                                    ) {
                                      return (
                                        <li key={key}>
                                          {
                                            userPrintersData.find(
                                              (printer) =>
                                                printer.id ==
                                                job.jobPrinters[keyyy].printerID
                                            ).data.name
                                          }

                                          <ul>
                                            {Object.keys(
                                              job.jobPrinters[keyyy]
                                            ).map((keyy, key) => {
                                              return (
                                                <li
                                                  key={key}
                                                >{`${keyy} : ${job.jobPrinters[keyyy][keyy]}`}</li>
                                              );
                                            })}
                                          </ul>
                                        </li>
                                      );
                                    }
                                  }
                                )
                              : ""}
                          </ol>
                        </span>
                      </div>
                      <br></br>
                      <br></br>
                    </li>
                  );
                })}
              </ol>
            </div>
          </section>
        </>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Dashboard;
